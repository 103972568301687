import gql from 'graphql-tag'

export const viewWelcome = gql`
  mutation($enrollmentId: ID!) {
    viewWelcome(enrollmentId: $enrollmentId) {
      id
      viewedWelcome
    }
  }
`

export const inviteAFriend = gql`
  mutation($name: String!, $friendsEmail: String!, $programId: ID!) {
    inviteAFriend(name: $name, friendsEmail: $friendsEmail, programId: $programId) {
      id
    }
  }
`

export const unsubscribe = gql`
  mutation($enrollmentId: ID!) {
    unsubscribe {
      id
      subscribed
    }
  }
`
