import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import sortBy from 'lodash/sortBy'

import { paths, buildLink } from '../../router'
import { track } from '../../analytics'
import {
  SnippetOption as Option,
  NarrowContainer,
  Consequences,
  Rationale,
  SnippetResources as Resources,
  Button,
  ButtonRow,
} from '../'
import { mutations } from '../../api'

import styles from './snippet-question.module.scss'

const SnippetQuestion = ({
  program,
  question,
  summary,
  resources,
  descriptionReferences,
  snippetId,
  enrollmentId,
  trackingContext,
  difficulty,
}) => {
  const { options, rationale } = question
  const [activeOption, setActiveOption] = useState(null)
  const [showRationale, setShowRationale] = useState(false)
  const [chosenOptions, setChosenOptions] = useState({})
  const questionRef = useRef(null)
  const { programSlug } = useParams()
  const [answerSnippet] = useMutation(mutations.snippets.answer)

  const bestAnswer = sortBy(options, 'score').pop() // Highest score

  useEffect(() => {
    track('STARTED_SNIPPET', trackingContext)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const confirmAnswer = async () => {
    setShowRationale(true)
    track('CONFIRMED_ANSWER', {
      optionId: options[activeOption].id,
      optionScore: options[activeOption].score,
      optionRariseStage: options[activeOption].rariseStage,
      optionTags: options[activeOption].measurementTags?.join('~'),
      snippetDifficulty: difficulty,
      ...trackingContext,
    })
    if (!enrollmentId) return
    try {
      const variables = {
        responseId: parseInt(options[activeOption].id),
        enrollmentId,
        snippetId,
      }
      await answerSnippet({ variables })
    } catch (error) {
      console.log(error)
    }
  }

  const onChooseOption = (option) => {
    setActiveOption(option)
    setChosenOptions({ ...chosenOptions, [option]: 'chosen' })
    track('OPTION_CLICKED', {
      optionId: options[option].id,
      optionScore: options[option].score,
      optionRariseStage: options[option].rariseStage,
      optionTags: options[option].measurementTags?.join('~'),
      ...trackingContext,
    })
  }

  const onPickAgain = () => {
    setActiveOption(null)
    setShowRationale(false)
    track('PICK_AGAIN', trackingContext)
  }

  const onTryAgain = () => {
    typeof window !== 'undefined' &&
      window.scrollTo({ left: 0, top: questionRef.current.offsetTop, behavior: 'smooth' })
    setActiveOption(null)
    setShowRationale(false)
    setChosenOptions({})
    track('TRY_AGAIN', trackingContext)
  }
  return (
    <>
      <div ref={questionRef} className={styles.snippetOptions}>
        <NarrowContainer>
          <h3>{question.title}</h3>
          <p className="sr-only">
            <FormattedMessage
              id="phrase.snippetInstructions"
              defaultMessage="Select what you believe to be the best response."
            />
          </p>
          {options.map(
            (option, index) =>
              (activeOption === null || activeOption === index) && (
                <Option
                  key={index}
                  title={option.title}
                  details={option.description}
                  onAnswer={(answer) => onChooseOption(answer)}
                  index={index}
                  disabled={activeOption !== null}
                />
              )
          )}
        </NarrowContainer>
      </div>
      {activeOption !== null && (
        <Consequences
          program={program}
          description={options[activeOption].consequences}
          onPickAgain={onPickAgain}
          onHappy={confirmAnswer}
          hideButtons={showRationale}
        />
      )}
      {showRationale && (
        <>
          <div className={styles.snippetOptions}>
            <NarrowContainer>
              <h3>
                <FormattedMessage id="headings.bestOption" defaultMessage="The Best Option" />
              </h3>
              <Option
                title={bestAnswer.title}
                index={options.indexOf(bestAnswer)}
                details={bestAnswer.description}
                disabled={true}
              />
            </NarrowContainer>
          </div>
          <Rationale
            program={program}
            summary={summary}
            description={rationale}
            onTryAgain={onTryAgain}
            references={descriptionReferences}
          />
          {resources && resources.length > 0 && (
            <Resources
              heading={<FormattedMessage id="headings.goDeeper" defaultMessage="Go Deeper" />}
              resources={resources}
              trackingContext={trackingContext}
            />
          )}
          <NarrowContainer className={styles.returnButtons}>
            <ButtonRow>
              <Button
                variant="grow"
                href={buildLink(program.trial ? paths.snippets.trialProgram : paths.snippets.program, {
                  programSlug: programSlug,
                })}
              >
                {<FormattedMessage id="cta.returnToProgram" defaultMessage="Return to program" />}
              </Button>
              <Button variant="grow" onClick={onTryAgain} testId="tryAgain">
                {<FormattedMessage id="cta.tryAgain" defaultMessage="Try again" />}
              </Button>
            </ButtonRow>
          </NarrowContainer>
        </>
      )}
    </>
  )
}

export default SnippetQuestion
