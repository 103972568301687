import React from 'react'
import cn from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../'
import { useAuth } from '../../auth'
import certificate from '../../shared/icons/certificate.png'

import styles from './program-certificate.module.scss'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const ProgramCertificate = ({ program, enrollment, card }) => {
  const { formatMessage } = useIntl()
  const [{ user }] = useAuth()

  const certificateAlt = formatMessage(
    { id: 'values.programCertificate', defaultMessage: '{program} Completion Certificate' },
    { program: program.name }
  )

  if (program.trial) return null

  return (
    <div className={cn({ [styles.certificate]: true, [styles.card]: card })}>
      <Button variant="unstyled" href={`${REACT_APP_API_URL}/certificates/${user.id}/${enrollment.id}`} target="_blank">
        <img src={certificate} alt={certificateAlt} />
        <div>
          <p className={styles.certificateTitle}>
            <FormattedMessage id="headings.complete" defaultMessage="Complete" />
          </p>
          <p>
            <FormattedMessage id="cta.viewCertificate" defaultMessage="View Certificate" />
          </p>
        </div>
      </Button>
    </div>
  )
}

export default ProgramCertificate
