import gql from 'graphql-tag'

export const getUserPrograms = gql`
  query {
    me {
      id
      enrollments {
        id
        trial
        userId
        viewedWelcome
        completedAt
        preAssessmentComplete
        postAssessmentComplete
        program {
          id
          name
          description
          slug
          icon
          signUpIntro
          trial
          welcomeTitle
          welcomeDescription
          locales
          preAssessment {
            id
            type
            title
            excerpt
          }
          postAssessment {
            id
            type
            title
            excerpt
          }
        }
        user {
          id
          email
        }
        responses {
          responseId
          snippetId
        }
        snippets {
          id
          excerpt
          title
          slug
        }
      }
    }
  }
`

export const getTrialPrograms = gql`
  query {
    getTrialPrograms {
      id
      name
    }
  }
`

export const getTrialProgram = gql`
  query($programSlug: String!) {
    getTrialProgram(programSlug: $programSlug) {
      id
      name
      slug
      icon
      snippets {
        id
        excerpt
        title
        slug
      }
    }
  }
`

export const getProgramSnippets = gql`
  query($programSlug: String!) {
    getProgramSnippets(programSlug: $programSlug) {
      id
      title
      excerpt
    }
  }
`

export const getUnsubscribedPrograms = gql`
  query {
    getUnsubscribedPrograms {
      id
      name
      description
      slug
      trial
      icon
      signUpIntro
      snippets {
        id
        excerpt
        title
        slug
      }
    }
  }
`

export const getProgramPreview = gql`
  query($id: Int!) {
    getProgramPreview(id: $id) {
      id
      name
      signUpIntro
    }
  }
`
