import React from 'react'
import { FormattedMessage } from 'react-intl'

const ProgramLocales = ({ locales, styles }) => {
  return (
    <p className={styles}>
      <b>
        <FormattedMessage id="availableIn" defaultMessage="Available in" />
        :&nbsp;
      </b>
      {locales.map((locale, index, arr) => {
        return (
          <span key={index}>
            &nbsp;
            <FormattedMessage id={locale} /> {index + 1 !== arr.length && '\u2022'}
          </span>
        )
      })}
    </p>
  )
}

export default ProgramLocales
