import React from 'react'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import DOMPurify from 'dompurify'
import { Button } from '../'
import styles from './snippet-option.module.scss'

const SnippetOption = ({ title, index, details, onAnswer, disabled }) => (
  <Button
    variant="unstyled"
    className={cn(styles.option, { [styles.disabled]: disabled })}
    onClick={() => onAnswer(index)}
    disabled={disabled}
  >
    <h4>
      <FormattedMessage id="option" defaultMessage="Option" /> {index + 1}
    </h4>
    <div className={styles.details} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(details) }}></div>
  </Button>
)

export default SnippetOption
