import React from 'react'

import { useAuth } from '../auth'
import { paths, useLocation, Redirect } from '../router'
import { LoadingSpinner } from '../components'

const PrivateRoute = ({ children }) => {
  const [{ authLoading, queryLoading, loggedIn, user }] = useAuth()
  const location = useLocation()

  const currentPath = location.pathname

  if (!loggedIn) {
    if (authLoading || (queryLoading && user == null)) {
      return (
        <div>
          <LoadingSpinner />
        </div>
      )
    }

    return <Redirect to={{ pathname: paths.auth.login, state: { returnTo: currentPath } }} />
  }

  return children
}

export default PrivateRoute
