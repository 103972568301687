import React from 'react'
import cn from 'classnames'
import styles from './dashboard-card.module.scss'

const DashboardCard = ({ title = '', subtitle = '', caption = '', uppercase = false }) => (
  <div className={cn({ [styles.dashboardCard]: true, [styles.uppercase]: uppercase })}>
    {title !== '' && <p className={styles.title}>{title}</p>}
    {subtitle !== '' && <p className={styles.subtitle}>{subtitle}</p>}
    {caption !== '' && <p className={styles.caption}>{caption}</p>}
  </div>
)

export default DashboardCard
