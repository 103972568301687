import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useIntl, FormattedMessage } from 'react-intl'
import DOMPurify from 'dompurify'

import { queries } from '../../../api'
import { SnippetQuestion as Question, NarrowContainer, Footnote, NotFound, LoadingSpinner } from '../../../components'
import { paths, buildLink, Link } from '../../../router'

import styles from './snippet.module.scss'

const TrialSnippet = () => {
  const { programSlug, snippetSlug } = useParams()
  const { formatMessage } = useIntl()
  const { loading, data } = useQuery(queries.snippets.getTrialProgramSnippet, {
    variables: { programSlug, snippetSlug },
  })
  // TODO: Refactor to remove snippet.program

  if (loading) return <LoadingSpinner />
  if (!data) return <NotFound />
  const {
    getTrialProgramSnippet: { program, snippet },
  } = data

  const title = {
    id: 'titles.pages.snippet',
    defaultMessage: snippet.title,
  }
  const trackingContext = {
    enrollmentId: null,
    snippetId: snippet.id,
    snippetTitle: snippet.title,
    programId: program.id,
    programTitle: program.name,
  }

  return (
    <main id="main-content">
      <Helmet title={formatMessage(title)} />
      <NarrowContainer>
        <Link
          className={styles.backToProgramLink}
          to={buildLink(paths.snippets.trialProgram, {
            programSlug,
          })}
          aria-label={<FormattedMessage id="cta.returnToProgram" defaultMessage="Return to program" />}
        >
          &lt;&lt; {program.name}
        </Link>
        <h1>{snippet.title}</h1>
        <p className={styles.intro} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(snippet.intro) }} />
        <Footnote content={snippet.introReferences} />
      </NarrowContainer>
      {snippet.question && (
        <Question
          program={program}
          question={snippet.question}
          summary={snippet.summary}
          resources={snippet.resources}
          snippetId={snippet.id}
          descriptionReferences={snippet.descriptionReferences}
          trackingContext={trackingContext}
          difficulty={snippet.difficulty}
        />
      )}
    </main>
  )
}

export default TrialSnippet
