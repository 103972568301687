import gql from 'graphql-tag'

export const login = gql`
  mutation($email: String, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

export const logout = gql`
  mutation {
    logout
  }
`

export const requestPasswordReset = gql`
  mutation($email: String!) {
    requestPasswordReset(email: $email)
  }
`

export const resetPassword = gql`
  mutation($token: String!, $password: String!, $passwordConfirmation: String!) {
    resetPassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation)
  }
`

export const setPassword = gql`
  mutation($confirmationToken: String!, $password: String!, $passwordConfirmation: String!) {
    setPassword(
      confirmationToken: $confirmationToken
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      token
    }
  }
`
