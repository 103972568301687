import gql from 'graphql-tag'

export const getAccount = gql`
  query($accountId: ID!) {
    getAccount(accountId: $accountId) {
      id
      name
      totalSeats
      notes
      accountUser {
        role
      }
      learners {
        id
        email
        firstName
        lastName
        accountUser {
          role
          createdAt
          updatedAt
          deletedAt
        }
      }
      leaders {
        id
        email
        firstName
        lastName
        accountUser {
          role
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`

export const getAccountProgramDetails = gql`
  query($accountId: ID!, $programSlug: String!) {
    getAccountProgramDetails(accountId: $accountId, programSlug: $programSlug) {
      program {
        id
        name
        description
        slug
        icon
        signUpIntro
        trial
        welcomeTitle
        welcomeDescription
        locales
      }
      schedule {
        activation
        preAssessment
        welcomeEmail
        snippets {
          id
          title
          releasedAt
        }
        postAssessment
        nudgeEmail
        completedAt
      }
      completions {
        name
        email
        preAssessmentComplete
        programComplete
        postAssessmentComplete
        subscribed
        responses {
          id
          responseId
          snippetId
        }
      }
    }
  }
`

export const getAccountProgramStats = gql`
  query($accountId: ID!, $programSlug: String!) {
    getAccountProgramStats(accountId: $accountId, programSlug: $programSlug) {
      program {
        id
        name
        description
        slug
        icon
        signUpIntro
        welcomeTitle
        welcomeDescription
        locales
        preAssessment {
          id
        }
        postAssessment {
          id
        }
      }
      learnerStats {
        learnerEnrollments
        learnerPreAssessments
        learnerStartedSnippets
        learnerProgramCompletions
        learnerPostAssessments
        snippetAverageTime
      }
      schedule {
        preAssessment
        postAssessment
        completedAt
      }
      engagementStats {
        snippetTitle
        releasedAt
        completions
        optionClicks
      }
      goDeeperStats {
        order
        resourceTitle
        resourceUrl
        snippetTitle
        resourceClicks
      }
      benchmarkStats {
        preAssessmentsCompleted
        snippetsStarted
        snippetsCompleted
        postAssessmentsCompleted
        averageEngagementCompletion
        timesMultipleOptionsWereExplored
        resourceClicks
        averageTimeSpentOnSnippet
      }
    }
  }
`

export const exportAccountData = gql`
  query($accountId: ID!) {
    exportAccountData(accountId: $accountId) {
      learners {
        id
        email
        firstName
        lastName
        accountUser {
          role
          createdAt
          updatedAt
          deletedAt
        }
      }
      programs {
        id
        name
        preAssessment {
          id
        }
      }
      completions {
        programId
        userId
        name
        email
        preAssessmentComplete
        programComplete
      }
    }
  }
`
