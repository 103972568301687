export const auth = {
  login: '/login/:provider?',
  signup: '/sign-up/:subject',
  signupGeneral: '/sign-up',
  signupTrial: '/sign-up?programId=6',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token',
}

export const pages = {
  home: '/',
  press: '/press',
  team: '/team',
  privacyPolicy: '/privacy-policy',
  profile: '/profile',
  inviteAFriend: '/invite-your-friends',
  unsubscribe: '/unsubscribe',
  confirmEmail: '/confirm/:confirmationToken',
}

export const snippets = {
  landing: '/snippets',
  program: '/snippets/:programSlug',
  snippet: '/snippets/:programSlug/:snippetSlug',
  trialProgram: '/trial/:programSlug',
  trialSnippet: '/trial/:programSlug/:snippetSlug',
  preAssessment: '/snippets/:programSlug/pre-assessment-survey',
  postAssessment: '/snippets/:programSlug/post-assessment-survey',
}

export const dashboard = {
  landing: '/dashboard',
  account: '/dashboard/:accountId',
  programDetails: '/dashboard/:accountId/programs/:programSlug',
  programStats: '/dashboard/:accountId/programs/:programSlug/stats',
}
