import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faBellSlash, faBell } from '@fortawesome/pro-light-svg-icons'
import { useQuery } from '@apollo/client'
import { LoadingSpinner, NotFound, Table, ProgramCard } from '../../components'
import { useParams } from '../../router'
import { queries } from '../../api'
import { useAccounts } from './dashboard'
import { useAuth } from '../../auth'
import styles from './program-details.module.scss'
import { render } from 'enzyme'

export const ProgramDetails = () => {
  const { accountId, programSlug } = useParams()
  // Only owners can see pre survey completions
  const { accounts } = useAccounts()
  const [{ user }] = useAuth()
  const account = accounts.find((account) => account.id === accountId)
  const isAccountOwner =
    !!accounts.find(
      (account) =>
        account.accountUser?.accountId === accountId &&
        (account.accountUser?.role === 'OWNER' || account.accountUser?.role === 'LEADER')
    ) || user.role === 'ADMIN'
  const {
    data: { getAccountProgramDetails: { program = {}, schedule = {}, completions = [] } = {} } = {},
    loading,
    error,
  } = useQuery(queries.account.getAccountProgramDetails, {
    variables: {
      accountId,
      programSlug,
    },
    // TODO: use apollo cache
    fetchPolicy: 'network-only',
  })
  if (loading) return <LoadingSpinner />
  if (!program || error) return <NotFound />

  return (
    <div>
      <div className={styles.programDetailsHeader}>
        <div>
          <h1>{account?.name}</h1>
          <h3>
            <FormattedMessage id="headings.programDetails" defaultMessage="Program Details" />
          </h3>
        </div>
        <ProgramStatus schedule={schedule} />
      </div>
      <ProgramCard program={program} linkToProgram={false} />
      <h3>
        <FormattedMessage id="headings.snippetSchedule" defaultMessage="Snippet Schedule" />
      </h3>
      <ProgramScheduleTable schedule={schedule} />
      <h3>
        <FormattedMessage id="headings.completion" defaultMessage="Completion" />
      </h3>
      <CompletionTable completions={completions} isAccountOwner={isAccountOwner} />
    </div>
  )
}

const ProgramStatus = ({ schedule = {} }) => {
  const { formatMessage } = useIntl()
  const { activation, completedAt } = schedule
  const currentDate = new Date().toISOString()
  let status = formatMessage({ id: 'phrase.dashboard.inProgress', defaultMessage: 'In Progress' })
  if (currentDate <= activation) {
    status = formatMessage({ id: 'phrase.dashboard.launching', defaultMessage: 'Launching' })
  } else if (currentDate >= completedAt) {
    status = formatMessage({ id: 'phrase.dashboard.complete', defaultMessage: 'Complete' })
  }
  return (
    <div className={styles.programStatus}>
      <p className={styles.programStatusTitle}>
        <FormattedMessage id="phrase.dashboard.programStatus" defaultMessage="Program Status" />:
      </p>
      <p className={styles.programStatusSubtitle}>{status}</p>
    </div>
  )
}

const ProgramScheduleTable = ({ schedule = {} }) => {
  const { formatMessage } = useIntl()
  const { activation, welcomeEmail, preAssessment, snippets = [], postAssessment, nudgeEmail } = schedule
  const headings = [
    formatMessage({ id: 'table.headings.date', defaultMessage: 'Date' }),
    formatMessage({ id: 'table.headings.snippet', defaultMessage: 'Snippet' }, { i: (text) => <i>{text}</i> }),
  ]

  const rows = []
  if (activation) {
    rows.push([
      formatDate(activation),
      formatMessage({
        id: 'table.rows.activationEmail',
        defaultMessage: 'Activation Email Sent',
      }),
    ])
  }
  if (welcomeEmail) {
    rows.push([
      formatDate(activation),
      formatMessage({ id: 'table.rows.welcomeEmail', defaultMessage: 'Welcome Email' }),
    ])
  }
  snippets.forEach((snippet, index) => {
    if (nudgeEmail && index === Math.ceil(snippets.length / 2)) {
      rows.push([
        formatDate(nudgeEmail),
        formatMessage({ id: 'table.rows.nudgeEmail', defaultMessage: 'Progress Update' }),
      ])
    }
    rows.push([formatDate(snippet.releasedAt), snippet.title])
  })
  if (postAssessment) {
    rows.push([
      formatDate(postAssessment),
      formatMessage({ id: 'table.rows.postAssessment', defaultMessage: 'Impact Survey' }),
    ])
  }

  return <Table headings={headings} rows={rows} size="sm" bordered striped />
}

const CompletionTable = ({ completions = [], isAccountOwner = false }) => {
  const { formatMessage } = useIntl()
  const headings = [
    formatMessage({ id: 'table.headings.name', defaultMessage: 'Name' }),
    formatMessage({ id: 'table.headings.email', defaultMessage: 'Email' }),
  ]
  if (isAccountOwner)
    headings.push(formatMessage({ id: 'table.headings.startedSnippets', defaultMessage: 'Started Snippets' }))
  headings.push(formatMessage({ id: 'table.headings.certificate', defaultMessage: 'Certificate Recieved' }))
  if (isAccountOwner)
    headings.push(formatMessage({ id: 'table.headings.postAssessment', defaultMessage: 'Post-Assessment' }))

  const rows = completions.map((completion) => {
    const row = [completion.name, completion.email]
    let startedSnippets = completion.responses?.length > 0 ? true : false
    if (isAccountOwner) row.push(startedSnippets ? renderCheckIcon() : null)
    row.push(completion.programComplete ? renderCheckIcon() : null)
    if (isAccountOwner) row.push(completion.postAssessmentComplete ? renderCheckIcon() : null)
    row.push(renderNotificationIcon(completion.subscribed))

    return row
  })
  headings.push(formatMessage({ id: 'table.headings.notifications', defaultMessage: 'Notifications' }))

  return (
    <Table
      headings={headings}
      rows={rows}
      size="md"
      searchable
      exportable={isAccountOwner}
      rowsPerPage={5}
      bordered
      className={styles.programCompletionTable}
    />
  )
}

const formatDate = (input) => {
  if (!input) return ''
  const date = new Date(input)
  return date.toDateString()
}

const renderCheckIcon = () => (
  <div className={styles.checkIcon}>
    <FontAwesomeIcon icon={faCheckCircle} />
  </div>
)

const renderNotificationIcon = (enabled) => (
  <div className={styles.checkIcon}>
    <FontAwesomeIcon icon={enabled ? faBell : faBellSlash} />
  </div>
)
