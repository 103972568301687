import React, { useState } from 'react'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FormCheck, FormGroup, FormLabel, FormControl, InputGroup, Button } from 'react-bootstrap'
import { useWatch } from 'react-hook-form'
import range from 'lodash/range'
import zxcvbn from 'zxcvbn'

import styles from './form.module.scss'

export const ScaleField = ({
  id,
  name,
  label,
  required,
  errors,
  testId,
  fieldRef,
  smallLabel,
  boldLabel,
  reverseScoring,
  scale = {
    start: 1,
    end: 5,
    step: 1,
  },
}) => (
  <FieldWrapper id={id} label={label} required={required} errors={errors} smallLabel={smallLabel} boldLabel={boldLabel}>
    <div className={cn(styles.radioInputGroup, { 'is-invalid': errors })}>
      {range(scale.start, scale.end + scale.step, scale.step).map((val) => (
        <FormCheck
          id={`${id}-${val}`}
          name={name}
          aria-invalid={errors ? 'true' : 'false'}
          className={cn(styles.input, styles.radioInput)}
          data-testid={testId}
          label={val}
          required={required}
          type="radio"
          key={val}
          ref={fieldRef}
          value={reverseScoring ? scale.end + scale.step - val : val}
          isInvalid={errors}
          inline
        />
      ))}
    </div>
  </FieldWrapper>
)

export const SelectField = ({
  id,
  name,
  label,
  required,
  errors,
  testId,
  fieldRef,
  options,
  defaultOption,
  defaultValue,
  smallLabel,
  boldLabel,
}) => (
  <FieldWrapper id={id} label={label} required={required} errors={errors} smallLabel={smallLabel} boldLabel={boldLabel}>
    <FormControl
      as="select"
      custom
      id={id}
      name={name}
      data-testid={testId}
      ref={fieldRef}
      isInvalid={errors}
      required={required}
      defaultValue={defaultValue}
    >
      {defaultOption && <option value="">{defaultOption}</option>}
      {options &&
        options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
    </FormControl>
  </FieldWrapper>
)

export const NumberField = ({
  id,
  name,
  label,
  required,
  errors,
  testId,
  fieldRef,
  defaultValue,
  placeholder,
  smallLabel,
  boldLabel,
  min,
  max,
  type = 'number',
  disabled = false,
}) => (
  <FieldWrapper id={id} label={label} required={required} errors={errors} smallLabel={smallLabel} boldLabel={boldLabel}>
    <FormControl
      id={id}
      name={name}
      type={type}
      data-testid={testId}
      ref={fieldRef}
      className={cn(styles.input)}
      required={required}
      defaultValue={defaultValue}
      isInvalid={errors}
      placeholder={placeholder}
      disabled={disabled}
      as={'input'}
      min={min}
      max={max}
    />
  </FieldWrapper>
)

export const TextField = ({
  id,
  name,
  label,
  required,
  errors,
  testId,
  fieldRef,
  defaultValue,
  placeholder,
  smallLabel,
  boldLabel,
  type = 'text',
  textArea = false,
  disabled = false,
}) => (
  <FieldWrapper id={id} label={label} required={required} errors={errors} smallLabel={smallLabel} boldLabel={boldLabel}>
    <FormControl
      id={id}
      name={name}
      type={type}
      data-testid={testId}
      ref={fieldRef}
      className={cn(styles.input)}
      required={required}
      defaultValue={defaultValue}
      isInvalid={errors}
      placeholder={placeholder}
      disabled={disabled}
      as={textArea ? 'textarea' : 'input'}
    />
  </FieldWrapper>
)

export const PasswordField = ({ id, name, label, required, errors, testId, fieldRef }) => {
  const [passwordShown, setPasswordShown] = useState(false)
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  return (
    <FieldWrapper id={id} label={label} required={required} errors={errors}>
      <InputGroup className={cn({ 'is-invalid': errors })}>
        <FormControl
          id={id}
          name={name}
          data-testid={testId}
          type={passwordShown ? 'text' : 'password'}
          ref={fieldRef}
          isInvalid={errors}
          className={cn(styles.input)}
        />
        <InputGroup.Append>
          <Button
            aria-label="Show password toggle"
            onClick={togglePasswordVisiblity}
            className={styles.showPasswordButton}
          >
            <FontAwesomeIcon icon={passwordShown ? faEyeSlash : faEye} />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </FieldWrapper>
  )
}

const FieldWrapper = ({ id, label, required, errors, smallLabel, boldLabel, children }) => {
  return (
    <FormGroup>
      <FormLabel
        htmlFor={id}
        className={cn(styles.label, { [styles.smallLabel]: smallLabel }, { [styles.boldLabel]: boldLabel })}
      >
        {label}
        {required && '*'}
      </FormLabel>
      {children}
      {errors && <FormControl.Feedback type="invalid">{errors.message}</FormControl.Feedback>}
    </FormGroup>
  )
}

// PasswordStrength box component. Uses control object from react-hook-form to watch the password field
const PasswordStrength = ({ control }) => {
  const password = useWatch({
    control,
    name: 'password',
    defaultValue: '',
  })
  const { score } = zxcvbn(password)
  // Get password strength text based on score and localization
  let text = <FormattedMessage id="passwordStrength.default" defaultMessage="Password Strength" />
  if (score === 0 && password !== '') {
    text = <FormattedMessage id="passwordStrength.veryWeak" defaultMessage="Very Weak" />
  } else if (score === 1) {
    text = <FormattedMessage id="passwordStrength.weak" defaultMessage="Weak" />
  } else if (score === 2) {
    text = <FormattedMessage id="passwordStrength.medium" defaultMessage="Medium" />
  } else if (score === 3) {
    text = <FormattedMessage id="passwordStrength.strong" defaultMessage="Strong" />
  } else if (score === 4) {
    text = <FormattedMessage id="passwordStrength.veryStrong" defaultMessage="Very Strong" />
  }
  return (
    <div>
      <div
        className={cn({
          [styles.passwordStrength]: true,
          weak: score < 3 && password !== '',
          strong: score >= 3,
        })}
      >
        <span>{text}</span>
      </div>
      <i className={styles.passwordStrengthMessage}>
        <FormattedMessage id="passwordStrength.message" defaultMessage="At minimum, password must be 'Strong'" />
      </i>
    </div>
  )
}

export const SetPasswordFields = ({ errors, registerField, control, watch }) => (
  <>
    <TextField
      id="password"
      name="password"
      testId="password"
      label={<FormattedMessage id="inputs.labels.password" defaultMessage="Password" />}
      errors={errors.password}
      type="password"
      fieldRef={registerField({
        required: <FormattedMessage id="errors.passwordRequired" defaultMessage="Please enter a password" />,
        validate: (value) => {
          const { score } = zxcvbn(value)
          if (score < 3) return <FormattedMessage id="errors.passwordTooWeak" defaultMessage="Password is too weak" />
          return true
        },
        maxLength: {
          value: 100,
          message: <FormattedMessage id="errors.fieldTooLong" defaultMessage="Field is too long" />,
        },
      })}
      required
    />
    <TextField
      id="passwordConfirmation"
      name="passwordConfirmation"
      testId="passwordConfirmation"
      label={<FormattedMessage id="inputs.labels.passwordConfirmation" defaultMessage="Password Confirmation" />}
      errors={errors.passwordConfirmation}
      type="password"
      fieldRef={registerField({
        required: (
          <FormattedMessage id="errors.passwordConfirmRequired" defaultMessage="Please confirm your password" />
        ),
        validate: (value) => {
          if (value !== watch('password')) {
            return <FormattedMessage id="errors.passwordNoMatch" defaultMessage="Passwords do not match" />
          }
          return true
        },
      })}
      required
    />
    <PasswordStrength control={control} />
  </>
)

export const SetPasswordError = () => (
  <>
    <FormattedMessage id="errors.passwordTokenExpired" defaultMessage="The link you’re using is invalid or expired." />{' '}
    <Link to="/">
      <FormattedMessage id="errors.returnHome" defaultMessage="Return Home." />
    </Link>
  </>
)
