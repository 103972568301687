import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/client'
import DOMPurify from 'dompurify'
import { mutations } from '../../../api'
import { Container } from '../../../components'

const Welcome = ({ title, description, cta, enrollmentId }) => {
  const [viewWelcome] = useMutation(mutations.programs.viewWelcome)
  useEffect(() => {
    viewWelcome({ variables: { enrollmentId } })
  }, [viewWelcome, enrollmentId])
  return (
    <Container>
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
      {cta}
      <br />
      <br />
      <p>
        <FormattedMessage
          id="needHelp"
          defaultMessage="Need help? Email us at <email>learningsnippets.com</email>"
          values={{
            email: (email) => <a href={`mailto:${email}`}>{email}</a>,
          }}
        />
      </p>
    </Container>
  )
}

export default Welcome
