import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Pages from '../pages'
import { NotFound } from '../components'
import { paths, PrivateRoute } from '../router'
import { DashboardPageWrapper } from '../pages/dashboard/dashboard'

const Routes = () => (
  <Switch>
    <Route exact path={paths.pages.home}>
      <Pages.Login />
    </Route>
    <Route exact path={paths.pages.press}>
      <Pages.Press />
    </Route>
    <Route exact path={paths.pages.team}>
      <Pages.Team />
    </Route>
    <Route exact path={paths.pages.unsubscribe}>
      <Pages.Unsubscribe />
    </Route>
    <Route path={paths.auth.login}>
      <Pages.Login />
    </Route>
    <Route exact path={paths.auth.forgotPassword}>
      <Pages.ForgotPassword />
    </Route>
    <Route exact path={paths.auth.resetPassword}>
      <Pages.ResetPassword />
    </Route>
    <Route exact path={paths.pages.inviteAFriend}>
      <Pages.InviteAFriend />
    </Route>
    <Route exact path={paths.snippets.preAssessment}>
      <PrivateRoute>
        <Pages.Snippets.Assessment type={'pre'} />
      </PrivateRoute>
    </Route>
    <Route exact path={paths.snippets.postAssessment}>
      <PrivateRoute>
        <Pages.Snippets.Assessment type={'post'} />
      </PrivateRoute>
    </Route>
    <Route exact path={paths.snippets.snippet}>
      <PrivateRoute>
        <Pages.Snippets.Snippet />
      </PrivateRoute>
    </Route>
    <Route exact path={paths.snippets.program}>
      <PrivateRoute>
        <Pages.Snippets.Program />
      </PrivateRoute>
    </Route>
    <Route exact path={paths.snippets.trialSnippet}>
      <Pages.Snippets.TrialSnippet />
    </Route>
    <Route exact path={paths.snippets.trialProgram}>
      <Pages.Snippets.TrialProgram />
    </Route>
    <Route exact path={paths.snippets.landing}>
      <PrivateRoute>
        <Pages.Snippets.Landing />
      </PrivateRoute>
    </Route>
    <Route
      exact
      path={[paths.auth.signup, paths.auth.signupGeneral]}
      component={() => {
        window.location.href = 'https://dialectic.solutions/learning-snippets/'
        return null
      }}
    />
    {/* <Pages.Signup /> */}
    <Route exact path={paths.dashboard.landing}>
      <PrivateRoute>
        <DashboardPageWrapper>
          <Pages.Dashboard.Landing />
        </DashboardPageWrapper>
      </PrivateRoute>
    </Route>
    <Route path={paths.dashboard.account}>
      <PrivateRoute>
        <DashboardPageWrapper>
          <Route exact path={paths.dashboard.account}>
            <Pages.Dashboard.AccountDetails />
          </Route>
          <Route exact path={paths.dashboard.programDetails}>
            <Pages.Dashboard.ProgramDetails />
          </Route>
          <Route exact path={paths.dashboard.programStats}>
            <Pages.Dashboard.ProgramStats />
          </Route>
        </DashboardPageWrapper>
      </PrivateRoute>
    </Route>
    <Route path={paths.pages.profile}>
      <PrivateRoute>
        <Pages.Profile />
      </PrivateRoute>
    </Route>
    <Route path={paths.pages.confirmEmail}>
      <Pages.ConfirmEmail />
    </Route>
    <Route path="/404">
      <NotFound />
    </Route>
    <Redirect to="/404" />
  </Switch>
)

export default Routes
