import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import DOMPurify from 'dompurify'
import ProgramLocales from '../../../components/program-locales/program-locales'

import { queries } from '../../../api'
import {
  SnippetQuestion as Question,
  NarrowContainer,
  Footnote,
  NotFound,
  LoadingSpinner,
  AssessmentModal,
} from '../../../components'

import styles from './snippet.module.scss'

const Snippet = () => {
  const { programSlug, snippetSlug } = useParams()
  const { loading, data } = useQuery(queries.snippets.getProgramSnippet, {
    variables: { programSlug, snippetSlug },
  })
  // TODO: Refactor to remove snippet.program
  if (loading) return <LoadingSpinner />
  if (!data) return <NotFound />
  const {
    getProgramSnippet: { program, snippet, enrollment },
  } = data

  const trackingContext = {
    enrollmentId: enrollment.id,
    snippetId: snippet.id,
    snippetTitle: snippet.title,
    programId: program.id,
    programTitle: program.name,
  }

  const preAssessmentIncomplete = program.preAssessment && !enrollment.preAssessmentComplete
  return (
    <main id="main-content">
      <Helmet title={snippet.title} />
      <NarrowContainer>
        <ProgramLocales styles={styles.translations} locales={program.locales} />
        <h1>{snippet.title}</h1>
        <p className={styles.intro} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(snippet.intro) }} />
        <Footnote content={snippet.introReferences} />
      </NarrowContainer>
      {snippet.question && (
        <Question
          program={program}
          question={snippet.question}
          summary={snippet.summary}
          resources={snippet.resources}
          enrollmentId={enrollment.id}
          snippetId={snippet.id}
          descriptionReferences={snippet.descriptionReferences}
          trackingContext={trackingContext}
          difficulty={snippet.difficulty}
        />
      )}
      <AssessmentModal isOpen={preAssessmentIncomplete} closable={false} programSlug={program.slug} />
    </main>
  )
}

export default Snippet
