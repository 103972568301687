import React from 'react'
import { FormattedMessage } from 'react-intl'
import { OverlayTrigger, Popover, PopoverTitle, PopoverContent } from 'react-bootstrap'
import cn from 'classnames'
import styles from './bar-chart.module.scss'

const BarChart = ({ scale = 1, data = [{}], legend = [] }) => {
  return (
    <div className={styles.barChartWrapper}>
      <Legend legend={legend} />
      <div className={styles.barChart}>
        <Scale />
        {data.map((category, index) => (
          <BarGroup key={index} label={category.label} values={category.values} scale={scale} legend={legend} />
        ))}
      </div>
    </div>
  )
}

const BarGroup = ({ label, values = [], scale, legend }) => {
  // Calculate percentage of each value based on scale prop
  const percentages = values.map((value, index) =>
    index === 0 ? Math.min(Math.round((value / scale) * 100), 100) || 0 : value
  )
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover id="bar-info">
          <PopoverTitle>{label}</PopoverTitle>
          <PopoverContent>
            {legend[0]}: <b>{percentages[0]}%</b>
            {percentages[1] && (
              <>
                <br />
                {legend[1]}: <b>{percentages[1]}%</b>
              </>
            )}
          </PopoverContent>
        </Popover>
      }
    >
      <div className={styles.barGroup}>
        {percentages.map(
          (percentage, index) =>
            percentage !== null && <Bar key={index} percentage={percentage} color={index === 0 ? 'orange' : 'red'} />
        )}
        {label && <p className={styles.barLabel}>{label}</p>}
      </div>
    </OverlayTrigger>
  )
}

const Bar = ({ percentage, color }) => (
  <div className={cn(styles.bar, color)} data-testid="bar-chart-bar">
    <div className={cn(styles.barFill, color)} style={{ height: `${percentage}%` }}>
      {percentage >= 10 && <p>{`${percentage}%`}</p>}
    </div>
  </div>
)

const Legend = ({ legend }) => (
  <div className={styles.legend}>
    {legend.map((item, index) => (
      <div className={styles.legendItem} key={index}>
        <div className={cn(styles.legendItemCircle, index === 0 ? 'orange' : 'red')} />
        <p>{item}</p>
      </div>
    ))}
  </div>
)

// Scale is static percents from 0-100
const Scale = () => (
  <div className={styles.scale}>
    <p className={styles.scaleLabel}>
      <FormattedMessage id="stats.dashboard.barChartScaleLabel" defaultMessage="% of Enrolled Learner(s)" />
    </p>
    <p>100%</p>
    <p>75%</p>
    <p>50%</p>
    <p>25%</p>
    <p>0%</p>
  </div>
)

export default BarChart
