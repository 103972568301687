import React from 'react'
import { useForm } from 'react-hook-form'
import { Spinner, Form } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'

import { Button } from '../../components'
import { useAuth } from '../../auth'
import { paths, useHistory } from '../../router'
import { SetPasswordFields, SetPasswordError } from '../field'
import { useNotifications } from '../../notifications'

import styles from '../form.module.scss'

const SetPasswordForm = ({ confirmationToken }) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const {
    register: registerField,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm()

  const [, { setPassword }] = useAuth()
  const [, { success: notifySuccess }] = useNotifications()

  const onSubmit = async ({ password, passwordConfirmation }) => {
    try {
      await setPassword({
        confirmationToken,
        password,
        passwordConfirmation,
      })

      notifySuccess({
        message: {
          id: 'phrase.accountActivated',
          defaultMessage: 'Thank you! Your account has been activated.',
        },
      })

      history.push(paths.snippets.landing)
    } catch (error) {
      if (error.graphQLErrors) {
        setError('base', {
          message: <SetPasswordError />,
        })
      } else {
        setError('base', {
          message: formatMessage({
            id: 'errors.internalServerError',
            defaultMessage: 'There was a problem processing your request, please try again later.',
          }),
        })
      }
    }
  }
  return (
    <>
      <h2>
        <FormattedMessage
          id="phrase.welcomeSetPassword"
          defaultMessage="Welcome! Please set a password to access your account."
        />
      </h2>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <SetPasswordFields
          errors={errors}
          required={true}
          registerField={registerField}
          control={control}
          watch={watch}
        />
        {errors.base && <div className={styles.errorAlertBox}>{errors.base.message}</div>}
        <Button
          type="submit"
          variant="primary"
          disabled={isSubmitting}
          className={styles.formButton}
          onClick={() => clearErrors('base')}
        >
          {isSubmitting ? (
            <>
              <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />
              <span className="sr-only">
                <FormattedMessage id="loading" defaultMessage="Loading" />
                ...
              </span>
            </>
          ) : (
            <FormattedMessage id="cta.setPassword" defaultMessage="Set Password" />
          )}
        </Button>
      </Form>
    </>
  )
}

export default SetPasswordForm
