import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Button } from '../index'

import styles from './footer.module.scss'

const Footer = () => (
  <footer className={styles.footer}>
    <Container>
      <Row className="justify-content-between align-items-center">
        <p className={styles.text}>
          © {new Date().getFullYear()}
          {' Learning Snippets. '}
          <FormattedMessage id="footer.copyright" defaultMessage="All rights reserved" />. |{' '}
          <Button variant="link" href="https://dialectic.solutions/privacy-policy/" exact>
            <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy Policy" />
          </Button>{' '}
          |{' '}
          <Button variant="link" href="https://dialectic.solutions/terms-of-use/" target="_blank" exact>
            <FormattedMessage id="footer.termsOfUse" defaultMessage="Terms of Use" />
          </Button>{' '}
          |{' '}
          <Button variant="link" href="https://www.learning-snippets.com/different/" target="_blank" exact>
            <FormattedMessage id="footer.different" defaultMessage="Would you respond in a different way?" />
          </Button>
        </p>
        <p className={styles.text}>
          <FormattedMessage id="footer.poweredBy" defaultMessage="Powered By" />{' '}
          <Button variant="link" href="https://dialectic.solutions/">
            Dialectic
          </Button>
        </p>
      </Row>
    </Container>
  </footer>
)

export default Footer
