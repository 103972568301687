import React from 'react'
import { useForm } from 'react-hook-form'
import { Spinner, Form } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'

import { Button } from '../../components'
import { mutations } from '../../api'
import { paths } from '../../router'
import { SetPasswordFields, SetPasswordError } from '../field'

import styles from '../form.module.scss'

const ResetPasswordForm = ({ token }) => {
  const { formatMessage } = useIntl()
  const {
    register: registerField,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm()
  const [resetPassword] = useMutation(mutations.auth.resetPassword)

  const onSubmit = async ({ password, passwordConfirmation }) => {
    try {
      await resetPassword({
        variables: {
          token,
          password,
          passwordConfirmation,
        },
      })
    } catch (error) {
      console.error(error)
      if (error.graphQLErrors) {
        setError('base', {
          message: <SetPasswordError />,
        })
      } else {
        setError('base', {
          message: formatMessage({
            id: 'errors.internalServerError',
            defaultMessage: 'There was a problem processing your request, please try again later.',
          }),
        })
      }
    }
  }
  return (
    <>
      {isSubmitSuccessful ? (
        <div>
          <p>
            <FormattedMessage id="phrase.passwordReset" defaultMessage="Your password has been reset successfully." />
          </p>
          <Button href={paths.auth.login}>
            <FormattedMessage id="titles.pages.login" defaultMessage="Login" />
          </Button>
        </div>
      ) : (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <SetPasswordFields
            errors={errors}
            required={true}
            registerField={registerField}
            control={control}
            watch={watch}
          />
          {errors.base && <div className={styles.errorAlertBox}>{errors.base.message}</div>}
          <Button
            type="submit"
            variant="primary"
            disabled={isSubmitting}
            className={styles.formButton}
            onClick={() => clearErrors('base')}
          >
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />
                <span className="sr-only">
                  <FormattedMessage id="phrase.loading" defaultMessage="Loading" />
                  ...
                </span>
              </>
            ) : (
              <FormattedMessage id="cta.changePassword" defaultMessage="Change Password" />
            )}
          </Button>
        </Form>
      )}
    </>
  )
}

export default ResetPasswordForm
