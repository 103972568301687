import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  Table as ReactBootstrapTable,
  InputGroup,
  FormControl,
  Col,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { Row } from '../index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { faChevronLeft, faChevronRight, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import styles from './table.module.scss'

// Prop spread is for react bootstrap props
// https://react-bootstrap.github.io/components/table/#table-props
const Table = ({
  headings = [],
  rows = [[]],
  searchable = false,
  exportable = false,
  rowsPerPage = null,
  ...props
}) => {
  // Pagination consumes filtered rows, which are modified by the search
  const [filteredRows, setFilteredRows] = useState(rows)
  // Current rows that are visible to the user
  const [visibleRows, setVisibleRows] = useState(rows)
  const paginated = rowsPerPage

  // Refresh on row change
  useEffect(() => {
    paginated ? setFilteredRows(rows) : setVisibleRows(rows)
  }, [paginated, rows])

  return (
    <div className={styles.tableWrapper}>
      {(searchable || exportable) && (
        <Row className="mb-3">
          <Col md="4">
            {searchable && <SearchFilter rows={rows} setRows={paginated ? setFilteredRows : setVisibleRows} />}
          </Col>
          <Col md={{ span: 1, offset: 7 }}>
            {exportable && <CSVExport headings={headings} rows={paginated ? filteredRows : visibleRows} />}
          </Col>
        </Row>
      )}
      <TableView headings={headings} rows={visibleRows} {...props} />
      <Row className="mb-3">
        <Col md={{ span: 4, offset: 8 }}>
          {paginated && (
            <Pagination rowsPerPage={rowsPerPage} rows={searchable ? filteredRows : rows} setRows={setVisibleRows} />
          )}
        </Col>
      </Row>
    </div>
  )
}

const SearchFilter = ({ rows, setRows }) => {
  const { formatMessage } = useIntl()
  const [search, setSearch] = useState('')
  const onChangeHandler = (e) => {
    const inputString = e.target.value
    inputString ? setSearch(inputString) : setSearch('')
  }

  useEffect(() => {
    if (!search) {
      setRows(rows)
    } else {
      setRows(
        rows.filter((rowArray) =>
          rowArray.some(
            (rowValue) => typeof rowValue === 'string' && rowValue.toLowerCase().includes(search.toLowerCase())
          )
        )
      )
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InputGroup>
      <InputGroup.Text id="basic-addon1" className={styles.inputAddon}>
        <FontAwesomeIcon icon={faSearch} />
      </InputGroup.Text>
      <FormControl
        aria-label={formatMessage({ id: 'table.search', defaultMessage: 'Search Table Rows' })}
        onChange={onChangeHandler}
      />
    </InputGroup>
  )
}

// Consumes filtered rows, changes visible rows
const Pagination = ({ rowsPerPage, rows, setRows }) => {
  const { formatMessage } = useIntl()
  const ofText = formatMessage({ id: 'table.pagination.of', defaultMessage: 'of' })

  const [page, setPage] = useState(1)
  const totalRows = rows.length
  const totalPages = Math.ceil(totalRows / rowsPerPage)
  const noMorePages = page >= totalPages

  const startRow = (page - 1) * rowsPerPage
  const remainder = totalRows - totalPages * rowsPerPage
  const endRow = noMorePages ? page * rowsPerPage + remainder : page * rowsPerPage

  useEffect(() => {
    const paginatedRows = rows.slice(startRow, endRow)
    setRows(paginatedRows)
  }, [rows.length, page]) // eslint-disable-line react-hooks/exhaustive-deps

  // Reset page state if search changes
  useEffect(() => {
    if (page > totalPages) setPage(1)
  }, [rows.length]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.pagination}>
      <p className={styles.paginationText}>
        {rows.length === 0 ? `0 ${ofText} 0` : `${page === 1 ? 1 : startRow}-${endRow} ${ofText} ${totalRows}`}
      </p>
      <Button
        size="sm"
        disabled={page === 1}
        onClick={() => setPage(page - 1)}
        className={styles.paginationButton}
        aria-label={formatMessage({ id: 'table.pagination.previous', defaultMessage: 'Previous Page in Table' })}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Button>
      <Button
        size="sm"
        disabled={noMorePages}
        onClick={() => setPage(page + 1)}
        className={styles.paginationButton}
        aria-label={formatMessage({ id: 'table.pagination.next', defaultMessage: 'Next Page in Table' })}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Button>
    </div>
  )
}

const CSVExport = ({ headings, rows }) => {
  // Turn all row values into exportable values
  const exportableRows = rows.map((rowArray) =>
    rowArray.map((rowValue) => {
      if (typeof rowValue === 'string') {
        return rowValue
      } else {
        return !!rowValue
      }
    })
  )
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          <FormattedMessage id="table.download" defaultMessage="Download CSV" />
        </Tooltip>
      }
    >
      <CSVLink
        data={[headings, ...exportableRows]}
        filename="dashboard.csv"
        className={styles.CSVButton}
        target="_blank"
      >
        <FontAwesomeIcon icon={faFileDownload} />
      </CSVLink>
    </OverlayTrigger>
  )
}

// Dumb table view component
const TableView = ({ headings, rows, className, ...props }) => (
  <ReactBootstrapTable responsive className={className} {...props}>
    <thead>
      <tr>
        {headings.map((heading, index) => (
          <th key={index}>{heading}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((rowArray, index) => (
        <tr key={index}>
          {rowArray.map((row, index) => (
            <td key={index}>{row}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </ReactBootstrapTable>
)

export default Table
