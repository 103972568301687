import React from 'react'

import { Helmet } from 'react-helmet-async'
import { useIntl, FormattedMessage } from 'react-intl'

import { Row, Col } from 'react-bootstrap'
import { Redirect, useLocation, useParams, useSearchParams, paths } from '../../router'

import styles from './login.module.scss'

import { LoginForm } from '../../forms'
import { NarrowContainer, Forbidden, Button } from '../../components'
import { useAuth } from '../../auth'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const Login = () => {
  const [{ loggedIn }] = useAuth()
  const { formatMessage } = useIntl()
  const location = useLocation()
  const { error } = useSearchParams()
  //we fetch the "provider" value from the url to create an SSO login page
  //The value used in the url becomes tha provider value saved to the db and should match the exactly with the api /login/:provider we've setup for the api's sso auth
  const acceptedProviders = ['google']
  const { provider } = useParams()

  let title = formatMessage({
    id: 'titles.pages.login',
    defaultMessage: 'Log In',
  })

  if (loggedIn) return <Redirect to={location.state?.returnTo || paths.snippets.landing} />

  if (provider && !acceptedProviders.includes(provider)) return <Redirect to={'/login'} />

  const providerTitle = provider
    ? provider.replace(/-/g, ' ').replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    : ''
  if (providerTitle) title = `${providerTitle} ` + title

  return (
    <main id="main-content">
      <Helmet title={title} />
      {error && (
        <NarrowContainer>
          <Forbidden error={error} />
        </NarrowContainer>
      )}
      <NarrowContainer className={styles.loginContainer}>
        <Row>
          <Col md={6}>
            <div className={styles.loginContent}>
              <h2>
                <FormattedMessage id="headings.signIn" defaultMessage="Log In" />
              </h2>
              <p>
                <FormattedMessage id="phrase.alreadySubscriber" defaultMessage="Already a Subscriber? Login Here." />
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className={styles.loginContent}>
              {provider ? (
                <Button variant="primary" href={`${REACT_APP_API_URL}/login/${provider}`}>
                  <FormattedMessage id="cta.signInWith" defaultMessage="Sign in with" /> {providerTitle}
                </Button>
              ) : (
                <LoginForm />
              )}
            </div>
          </Col>
        </Row>
      </NarrowContainer>
    </main>
  )
}

export default Login
