import React, { useState } from 'react'
import { useEffect } from 'react'

export const useIsTabbing = () => {
  const [isTabbing, setIsTabbing] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const windowExists = typeof window !== undefined

  useEffect(() => {
    const handleFirstTab = (e) => {
      if (e.keyCode === 9) {
        document.body.classList.add('is-tabbing')
        window.removeEventListener('keydown', handleFirstTab)
        window.addEventListener('mousedown', handleMouseDownOnce)
      }
    }
    const handleMouseDownOnce = () => {
      document.body.classList.remove('is-tabbing')
      window.removeEventListener('mousedown', handleMouseDownOnce)
      window.addEventListener('keydown', handleFirstTab)
    }
    if (!isMounted && windowExists) {
      setIsMounted(true)
      window.addEventListener('keydown', handleFirstTab)
    }
    return () => {
      window.removeEventListener(handleFirstTab)
      window.addEventListener(handleMouseDownOnce)
    }
  }, [windowExists]) // eslint-disable-line react-hooks/exhaustive-deps

  return [isTabbing, setIsTabbing]
}
