import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'

const Team = () => {
  const { formatMessage } = useIntl()
  const title = {
    id: 'titles.pages.team',
    defaultMessage: 'Team',
  }
  return (
    <>
      <Helmet title={formatMessage(title)} />
      <div>This is the Team page</div>
    </>
  )
}

export default Team
