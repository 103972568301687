import * as Snippets from './snippets'
import ConfirmEmail from './confirm-email/confirm-email'
import InviteAFriend from './invite-a-friend/invite-a-friend'
import Login from './login/index'
import Press from './press'
import Profile from './profile/profile'
import * as Dashboard from './dashboard/index'
import Signup from './sign-up'
import Team from './team'
import Unsubscribe from './unsubscribe/unsubscribe'
import ForgotPassword from './forgot-password'
import ResetPassword from './reset-password'

export default {
  ConfirmEmail,
  InviteAFriend,
  Login,
  Press,
  Dashboard,
  Profile,
  Signup,
  Snippets,
  Team,
  Unsubscribe,
  ForgotPassword,
  ResetPassword,
}
