import React from 'react'
import cn from 'classnames'
import styles from './sidebar.module.scss'

export const Sidebar = (props) => (
  <div className={cn([styles.sidebarContainer, props.className])}>
    <aside {...props} className={cn(styles.sidebar, props.className)}>
      {props.children}
    </aside>
  </div>
)
