import React from 'react'
import cn from 'classnames'
import ConditionalWrap from 'conditional-wrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Button as BootstrapButton } from 'react-bootstrap'

import styles from './button.module.scss'

const Button = React.forwardRef(
  (
    {
      variant = 'primary',
      className,
      href,
      target,
      replace = false,
      as,
      block,
      disabled,
      size,
      type,
      exact,
      activeClassName = 'button-active',
      onClick,
      testId,
      children,
    },
    ref
  ) => {
    const isExternalURL = /^https?:\/\//.test(href)
    const variants = variant.split(' ')
    const variantClasses = variants.map((variant) => styles[variant])
    return (
      <ConditionalWrap
        condition={href && !isExternalURL}
        wrap={(button) => (
          <LinkContainer to={href} exact={exact} activeClassName={activeClassName} replace={replace} ref={ref}>
            {button}
          </LinkContainer>
        )}
      >
        <BootstrapButton
          href={isExternalURL ? href : undefined}
          target={target}
          as={as}
          block={block}
          disabled={disabled}
          size={size}
          type={type}
          className={cn(
            { [styles.button]: variant !== 'link' },
            variantClasses,
            {
              [styles[size]]: styles[size] !== 'undefined',
            },
            className
          )}
          variant={variant}
          onClick={onClick}
          data-testid={testId}
        >
          {children}
        </BootstrapButton>
      </ConditionalWrap>
    )
  }
)

export default Button
