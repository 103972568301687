import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useQuery, useMutation } from '@apollo/client'
import { useIntl, FormattedMessage } from 'react-intl'

import { queries, mutations } from '../../api'
import { paths, buildLink, Redirect } from '../../router'
import { ProgramCard, Container, LoadingSpinner } from '../../components'

const LandingWrapper = () => {
  const { loading, data } = useQuery(queries.programs.getUserPrograms)

  if (loading) return <LoadingSpinner />

  const enrollments = data?.me.enrollments

  if (enrollments?.length === 1 && !enrollments[0].viewedWelcome) {
    return <Redirect to={buildLink(paths.snippets.program, { programSlug: enrollments[0].program.slug })} />
  }

  return <Landing enrollments={enrollments} />
}

const Landing = ({ enrollments }) => {
  const { formatMessage } = useIntl()
  const { search } = useLocation()
  const queryVars = queryString.parse(search)
  const title = {
    id: 'titles.pages.snippetsLanding',
    defaultMessage: 'Snippets Programs',
  }
  const [activate] = useMutation(mutations.users.activate)

  useEffect(() => {
    const activateUser = async () => {
      if (queryVars.activate) {
        try {
          await activate()
        } catch (error) {
          console.log(error)
        }
      }
    }

    activateUser()
  })

  return (
    <>
      <Helmet title={formatMessage(title)} />
      <main id="main-content">
        <Container>
          <h2>
            <FormattedMessage id="headings.myPrograms" defaultMessage="My Programs" />
          </h2>
          {enrollments &&
            enrollments.map((enrollment, i) => (
              <ProgramCard key={i} program={enrollment.program} enrollment={enrollment} />
            ))}
        </Container>
      </main>
    </>
  )
}

export default LandingWrapper
