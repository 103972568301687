import gql from 'graphql-tag'

export const getAssessment = gql`
  query($programSlug: String!) {
    getEnrolledProgram(programSlug: $programSlug) {
      id
      preAssessmentComplete
      postAssessmentComplete
      completedAt
      program {
        id
        name
        preAssessment {
          id
          type
          title
          excerpt
          introduction
          sections {
            id
            title
            legend
            randomize
            questions {
              id
              title
              type
              scaleStart
              scaleEnd
              scaleStep
            }
          }
        }
        postAssessment {
          id
          type
          title
          excerpt
          introduction
          sections {
            id
            title
            legend
            randomize
            questions {
              id
              title
              type
              scaleStart
              scaleEnd
              scaleStep
            }
          }
        }
      }
    }
  }
`
