import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuth } from '../auth'

const trackPageView = () => {
  if (!window?.analytics) return
  window.analytics.page()
}

export const PageTracker = () => {
  const history = useHistory()

  useEffect(() => {
    trackPageView() // on load
    history.listen(trackPageView) // on route change
  }, [history])

  return null
}

export const IdentityTracker = () => {
  const [{ user }] = useAuth()

  useEffect(() => {
    if (!user || !window?.analytics) return
    window.analytics.identify(user.id, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      company: user.profile.company,
      industry: user.profile.industry,
      preferredPronouns: user.profile.preferredPronouns,
      organizationId: user.organizationId,
    })
  }, [user])

  return null
}

export const track = (...args) => {
  if (!window?.analytics) return
  return window.analytics.track(...args)
}

export const trackLink = (...args) => {
  if (!window?.analytics) return
  return window.analytics.trackLink(...args)
}

export default {
  PageTracker,
  IdentityTracker,
}
