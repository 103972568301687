import React, { useState } from 'react'
import cn from 'classnames'
import { Container, Row, Dropdown } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import OutsideClickHandler from 'react-outside-click-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons'
import { useAuth } from '../../auth'
import { useLocale, SUPPORTED_LOCALES } from '../../i18n'
import { paths, buildLink, Link } from '../../router'
import { Button } from '../'
import styles from './header.module.scss'
import logo from './logo.png'

const Header = ({ hasBackground = true, onHomepage = false }) => {
  const [navOpen, setNavOpen] = useState(false)
  return (
    <nav className={cn(styles.navContainer, { [styles.navBackground]: hasBackground })}>
      <Container className={cn(styles.header, 'd-flex', 'justify-content-between', 'align-items-center')}>
        {onHomepage ? (
          <Row>hello@learningsnippets.ca</Row>
        ) : (
          <Link to="/" aria-label="Learning Snippets Home">
            <img className={styles.logo} src={logo} alt="" />
          </Link>
        )}
        <Link className="sr-only sr-only-focusable text-white p-1" to={{ hash: '#main-content' }}>
          <FormattedMessage id="phrase.skipToContent" defaultMessage="Skip to content" />
        </Link>
        <Row className={cn('justify-content-end', 'd-lg-flex', 'd-none', 'align-items-center')}>
          <Nav />
        </Row>
        <Row className={cn('justify-content-end', 'd-lg-none')}>
          <Button className={styles.navToggle} onClick={() => setNavOpen(true)} testId="mobileNavToggle">
            <FontAwesomeIcon size="lg" icon={faBars} />
          </Button>
        </Row>
        <div className={cn(styles.mobileNavShadow, { [styles.showShadow]: navOpen })} />
      </Container>
      <OutsideClickHandler onOutsideClick={() => navOpen && setNavOpen(false)}>
        <div className={cn(styles.mobileNav, 'd-lg-none', { [styles.openNav]: navOpen })} data-testid="mobileNav">
          <Button
            className={styles.closeNav}
            onClick={() => {
              setNavOpen(false)
            }}
          >
            <FontAwesomeIcon size="lg" icon={faTimes} />
          </Button>
          <Nav onClick={() => setNavOpen(false)} />
        </div>
      </OutsideClickHandler>
    </nav>
  )
}

const Nav = ({ onClick }) => {
  const [{ user = {}, loggedIn } = {}, { logout } = {}] = useAuth()
  const { formatMessage } = useIntl()

  const onLogout = (e) => {
    onClick && onClick()
    logout(e)
  }

  const enrollments = user?.enrollments?.map((enrollment) => ({
    label: enrollment.program.name,
    href: buildLink(paths.snippets.program, { programSlug: enrollment.program.slug }),
  }))

  return (
    <>
      {loggedIn ? (
        <>
          {user?.hasDashboard && (
            <Button
              className={cn(styles.headerButton, styles.lead)}
              href="/dashboard"
              activeClassName="active"
              variant="outline"
              onClick={onClick}
              exact
            >
              <FormattedMessage id="titles.pages.dashboard" href="/dashboard" defaultMessage="Dashboard" />
            </Button>
          )}
          <NavDropdown
            label={formatMessage({ id: 'header.snippets', defaultMessage: 'Snippets' }, { i: (text) => <i>{text}</i> })}
            items={enrollments}
          />
          <LanguageSwitcher />
          <Button className={styles.headerButton} variant="outline" onClick={onLogout}>
            <FormattedMessage id="header.logout" defaultMessage="Log Out" />
          </Button>
          <Button
            className={styles.headerButton}
            variant="outline"
            href="/profile"
            exact
            activeClassName="active"
            onClick={onClick}
          >
            <FontAwesomeIcon size="lg" icon={faCog} />
          </Button>
        </>
      ) : (
        <>
          <LanguageSwitcher />
          <Button
            className={styles.headerButton}
            variant="outline"
            href="/login"
            activeClassName="active"
            onClick={onClick}
          >
            <FormattedMessage id="header.login" defaultMessage="Log In" />
          </Button>
        </>
      )}
    </>
  )
}

const NavDropdown = ({ label, items = [] }) => {
  const [show, setShow] = useState(false)

  return (
    <Dropdown
      className={styles.headerDropdown}
      show={show}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Dropdown.Toggle id={`dropdown-${label}`} className={styles.headerDropdown} variant="outline">
        <Button className={cn(styles.dropdownToggle, styles.headerButton)} variant="outline" href="/snippets">
          {label}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        {items.map((item) => (
          <Link key={item.href} to={item.href} className={styles.dropdownLink}>
            {item.label}
          </Link>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const LanguageSwitcher = () => {
  const [show, setShow] = useState(false)
  const { locale, setLocale } = useLocale()

  return (
    <Dropdown
      className={cn(styles.headerDropdown, styles.languageSwitcher)}
      show={show}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      data-testid="language-switcher"
    >
      <Dropdown.Toggle id={`dropdown-${locale}`} className={styles.headerDropdown} variant="outline">
        <Button className={cn(styles.dropdownToggle, styles.headerButton)} variant="outline" as="a">
          <FormattedMessage id={locale} />
          <FontAwesomeIcon icon={faAngleDown} />
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        {SUPPORTED_LOCALES.map((item) => (
          <Dropdown.Item key={item} onClick={() => setLocale(item)} className={styles.dropdownLink}>
            <FormattedMessage id={item} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
export default Header
