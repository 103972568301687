import gql from 'graphql-tag'

export const answerAssessment = gql`
  mutation($enrollmentId: ID!, $type: String!, $answers: [AssessmentAnswerInput]!) {
    answerAssessment(enrollmentId: $enrollmentId, type: $type, answers: $answers) {
      id
      preAssessmentComplete
      postAssessmentComplete
    }
  }
`
