import { useEffect } from 'react'

import { useAuth } from '../auth'

const FontSize = () => {
  const [{ user }] = useAuth()

  useEffect(() => {
    if (!user) return
    document.getElementsByTagName('html')[0].setAttribute('style', `font-size: ${user.profile?.fontScale}rem`)
  }, [user])
  return null
}

export default FontSize
