import gql from 'graphql-tag'

export const enrollLearner = gql`
  mutation($accountId: ID!, $firstName: String!, $lastName: String!, $email: String!, $locale: Locale!) {
    enrollLearner(accountId: $accountId, firstName: $firstName, lastName: $lastName, email: $email, locale: $locale) {
      id
      firstName
      lastName
      email
    }
  }
`

export const unenrollLearner = gql`
  mutation($accountId: ID!, $email: String!) {
    unenrollLearner(accountId: $accountId, email: $email)
  }
`
