import React, { createContext, useContext } from 'react'
import { Row, Container, LoadingSpinner, Forbidden } from '../../components'
import { Col } from 'react-bootstrap'
import { DashboardSidebar as Sidebar } from './dashboard-sidebar'
import styles from './dashboard.module.scss'
import cn from 'classnames'
import { useQuery } from '@apollo/client'
import { queries } from '../../api'
import { buildLink, Redirect, paths } from '../../router'

const AccountsContext = createContext(null)

export const AccountsProvider = ({ children }) => {
  const { data: { getCurrentUserAccounts: accounts = [] } = {}, loading, error } = useQuery(
    queries.users.getCurrentUserAccounts
  )
  return <AccountsContext.Provider value={{ accounts, loading, error }}>{children}</AccountsContext.Provider>
}

export const useAccounts = () => {
  return useContext(AccountsContext)
}

export const DashboardPageWrapper = (props) => {
  return (
    <AccountsProvider>
      <Container className={cn(styles.dashboard)}>
        <Row>
          <Col sm={12} lg={4} xl={3}>
            <Sidebar />
          </Col>
          <Col sm={12} lg={8} xl={9} className={styles.dashboardCol}>
            <main id="main-content">{props.children}</main>
          </Col>
        </Row>
      </Container>
    </AccountsProvider>
  )
}

export const Landing = () => {
  const { accounts, loading } = useAccounts()
  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : accounts.length > 0 ? (
        <Redirect to={buildLink(paths.dashboard.account, { accountId: accounts[0].id })} />
      ) : (
        <Container>
          <Forbidden />
        </Container>
      )}
    </div>
  )
}
