import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Sidebar } from '../../components/sidebar/sidebar'
import { Dropdown, Accordion, Card } from 'react-bootstrap'
import Button from '../../components/button/button'
import styles from './dashboard-sidebar.module.scss'
import { paths, buildLink, useParams } from '../../router'
import cn from 'classnames'
import { useAccounts } from './dashboard'

export const DashboardSidebar = () => {
  const { accountId: accountIdParam } = useParams()
  const { accounts } = useAccounts()

  const account = accounts?.find((account) => account.id === accountIdParam) || accounts[0] || {}

  const [activeProgram, setActiveProgram] = useState(false)
  useEffect(() => {
    if (account?.programs) {
      setActiveProgram(account?.programs[0])
    }
  }, [account.id]) // eslint-disable-line react-hooks/exhaustive-deps
  if (!account.id) return null
  return (
    <Sidebar className={styles.sidebar}>
      <nav>
        <span className={styles.heading}>
          <FormattedMessage className="heading" id="inputs.labels.organization" defaultMessage="Organization" />
        </span>
        <Dropdown className={styles.dropdown}>
          <Dropdown.Toggle
            id={'organization-dropdown'}
            variant="outline"
            className={cn([styles.dropdownToggle, 'btn btn-outline'])}
          >
            {account.name}
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.dropdownMenu}>
            {accounts.map((account, index) => (
              <Dropdown.Item
                className={styles.dropdownMenuItem}
                as={Button}
                key={index}
                href={buildLink(paths.dashboard.account, { accountId: account.id })}
                activeClassName="active"
                variant="outline"
                exact
              >
                {account.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Button
          exact
          variant="outline"
          className={styles.link}
          href={buildLink(paths.dashboard.account, { accountId: account.id })}
        >
          <FormattedMessage id="links.dashboard.accountDetails" defaultMessage="Account Details" />
        </Button>
        {/* <hr /> */}
        <span className={styles.heading}>
          <FormattedMessage className="heading" id="inputs.labels.programs" defaultMessage="Organization" />
        </span>
        {account?.programs.length < 1 ? null : (
          <Accordion className={styles.accordion} activeKey={activeProgram.slug}>
            {account?.programs.map((program, index) => {
              return (
                <Card key={index} className={styles.card}>
                  <Card.Header className={styles.cardHeader}>
                    <Accordion.Toggle
                      variant="outline"
                      className={cn([{ active: activeProgram.slug === program.slug }])}
                      eventKey={program.slug}
                      onClick={() =>
                        activeProgram.slug === program.slug ? setActiveProgram({}) : setActiveProgram(program)
                      }
                    >
                      {program.name}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={program.slug}>
                    <Card.Body className={styles.cardBody}>
                      <Button
                        className={styles.link}
                        href={buildLink(paths.dashboard.programDetails, {
                          programSlug: program.slug,
                          accountId: account.id,
                        })}
                        activeClassName="active"
                        variant="outline"
                        exact
                      >
                        <FormattedMessage id="links.dashboard.programDetails" defaultMessage="Program Details" />
                      </Button>
                      <Button
                        className={styles.link}
                        href={buildLink(paths.dashboard.programStats, {
                          programSlug: program.slug,
                          accountId: account.id,
                        })}
                        activeClassName="active"
                        variant="outline"
                        exact
                      >
                        <FormattedMessage id="links.dashboard.programStats" defaultMessage="Metrics and Analysis" />
                      </Button>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )
            })}
          </Accordion>
        )}
      </nav>
    </Sidebar>
  )
}
