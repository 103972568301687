import React from 'react'
import { paths, buildLink, Link } from '../../router'
import { ProgramCertificate } from '../index'
import ProgramLocales from '../program-locales/program-locales'

import styles from './program-card.module.scss'

const ProgramCard = ({ program = {}, enrollment = {}, linkToProgram = true }) => {
  return (
    <div className={styles.programCard}>
      <ConditionalWrapper
        condition={linkToProgram}
        wrapper={(children) => (
          <Link to={buildLink(paths.snippets.program, { programSlug: program.slug })} className={styles.programLink}>
            {children}
          </Link>
        )}
      >
        <div className={styles.programContainer}>
          <img className={styles.icon} src={program.icon} alt={program.name} aria-hidden="true" />
          <div className={styles.programContent}>
            <h3 className={styles.programTitle}>{program.name}</h3>
            <p>{program.description}</p>
            <ProgramLocales styles={styles.translations} locales={program.locales} />
          </div>
        </div>
      </ConditionalWrapper>
      {enrollment.completedAt && <ProgramCertificate program={program} enrollment={enrollment} card />}
    </div>
  )
}

// Conditionally wrap children in Link component passed in wrapper
const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children)

export default ProgramCard
