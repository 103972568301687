import React from 'react'
import cn from 'classnames'
import { Container as BootstrapContainer } from 'react-bootstrap'

import styles from './container.module.scss'

const Container = (props) => <BootstrapContainer {...props} />

export const NarrowContainer = ({ className = '', ...props }) => (
  <BootstrapContainer {...props} className={cn(className, styles.narrow)} />
)

export default Container
