import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useMutation } from '@apollo/client'
import { mutations } from '../../api'
import { NarrowContainer, Button } from '../../components'
import { useEffect } from 'react'

const Unsubscribe = () => {
  const [unsubscribed, setUnsubscribed] = useState(null)
  const [unsubscribe] = useMutation(mutations.users.unsubscribe)
  const { search } = useLocation()
  const queryVars = queryString.parse(search)

  useEffect(() => {
    onUnsubscribe()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onUnsubscribe = async () => {
    try {
      const result = await unsubscribe({ variables: { email: queryVars.user } })
      if (result.data.unsubscribe === true) {
        setUnsubscribed('success')
      } else {
        setUnsubscribed('error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (unsubscribed === 'success') {
    return (
      <NarrowContainer>
        <p>
          <FormattedMessage
            id="phrase.unsubscribe.success"
            defaultMessage="You have unsubscribed from email communications."
          />
        </p>
      </NarrowContainer>
    )
  } else if (unsubscribed === 'error') {
    return (
      <NarrowContainer>
        <p>
          <FormattedMessage
            id="phrase.unsubscribe.error"
            defaultMessage="There was an error unsubscribing from email communications. Please try again."
          />
        </p>
      </NarrowContainer>
    )
  }

  return (
    <NarrowContainer>
      <h1>
        <FormattedMessage id="headings.unsubscribe" defaultMessage="Unsubscribe" />
      </h1>
      <p>
        <FormattedMessage
          id="phrase.unsubscribe.description"
          defaultMessage="You are attempting to unsubscribe from all email communications."
        />
      </p>
      <p>
        <Button variant="primary" onClick={onUnsubscribe}>
          <FormattedMessage id="cta.continue" defaultMessage="Continue" />
        </Button>
      </p>
    </NarrowContainer>
  )
}

export default Unsubscribe
