import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet-async'
import { useMutation } from '@apollo/client'

import { useParams, useHistory, paths, Link } from '../../router'
import { mutations } from '../../api'
import { useAuth } from '../../auth'
import { useNotifications } from '../../notifications'
import { SetPasswordForm } from '../../forms'
import { NarrowContainer, LoadingSpinner } from '../../components'

export default () => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { confirmationToken } = useParams()
  const [{ loggedIn }] = useAuth()
  const [confirmEmail, { loading, called }] = useMutation(mutations.registration.confirmEmail)
  const [, { success: notifySuccess }] = useNotifications()
  const [showPasswordForm, setShowPasswordForm] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!confirmationToken) {
      return
    }

    async function submit() {
      try {
        const { data } = await confirmEmail({ variables: { token: confirmationToken } })
        if (!data.confirmEmail.passwordSet) {
          setShowPasswordForm(true)
        } else {
          history.push(loggedIn ? paths.pages.home : paths.auth.login)
          notifySuccess({
            message: {
              id: 'notifications.confirmEmailSuccess',
              defaultMessage:
                'Email address verified. You can now update your preferences from your <profileLink>profile page.</profileLink>',
              values: {
                profileLink: (msg) => <Link to={paths.pages.profile}>{msg}</Link>,
              },
            },
          })
        }
      } catch (error) {
        setError(<FormattedMessage id="errors.emailConfirmation" defaultMessage="Email address confirmation failed." />)
      }
    }
    submit()
  }, [confirmationToken]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!called || loading) return <LoadingSpinner />

  return (
    <NarrowContainer>
      <Helmet
        title={formatMessage({
          id: 'titles.pages.confirmEmail',
          defaultMessage: 'Confirm Email',
        })}
      />

      {showPasswordForm && <SetPasswordForm confirmationToken={confirmationToken} />}
      {error}
    </NarrowContainer>
  )
}
