import gql from 'graphql-tag'

export const getCurrentUser = gql`
  query {
    getCurrentUser {
      id
      email
      role
      firstName
      lastName
      subscribed
      notified
      sendReminders
      profile {
        id
        preferredPronouns
        industry
        company
        fontScale
        address
        companyRole
        locale
      }
      enrollments {
        id
        program {
          id
          slug
          name
        }
      }
    }
  }
`

export const getCurrentUserAccounts = gql`
  query {
    getCurrentUserAccounts {
      id
      name
      programNotes
      accountUser {
        userId
        accountId
        role
      }
      programs {
        name
        slug
      }
      package {
        id
        title
        programs {
          name
          slug
        }
      }
    }
  }
`
