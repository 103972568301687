import React from 'react'
import FontSize from './font-size'

const UserSettings = () => (
  <>
    <FontSize />
  </>
)

export default UserSettings
