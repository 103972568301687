const buildLink = (path, pathVars = {}) => {
  let link = path
  Object.keys(pathVars).forEach((varName) => {
    link = link.replace(`:${varName}`, pathVars[varName])
  })

  return link
}

export default buildLink
