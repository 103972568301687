import React from 'react'
import BootstrapSpinner from 'react-bootstrap/Spinner'
import c from 'classnames'

import styles from './loading-spinner.module.scss'

const Spinner = ({ center = true, variant = 'primary' }) => (
  <div className={c(styles.root, { [styles.center]: center })}>
    <BootstrapSpinner animation="grow" variant={variant} size="sm" className={c(styles.icon)} />
    <BootstrapSpinner animation="grow" variant={variant} role="Loading" className={c(styles.icon)} />
    <BootstrapSpinner animation="grow" variant={variant} size="sm" className={c(styles.icon)} />
  </div>
)

const LoadingSpinner = ({ overlay = false, ...props }) => {
  if (overlay)
    return (
      <div className={styles.overlay}>
        <Spinner {...props} />
      </div>
    )

  return <Spinner {...props} />
}

export default LoadingSpinner
