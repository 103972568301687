import { createContext, useReducer, useCallback, useContext } from 'react'

const initialState = []

/**
 * Reducer for the notifications state
 *
 * @function reducer
 * @param {Object} state - Current state object
 * @param {Object} action - Action being performed
 * @param {string} action.type - Action type
 * @param {Object} [action.data] - Data associated with the action
 * @returns {Object} - New state object
 */
function reducer(state, { type, data }) {
  switch (type) {
    case 'notify':
      return [data, ...state]
    case 'dismiss':
    case 'timeout':
      const newState = [...state]
      const index = newState.findIndex((notification) => notification.id === data.id)
      if (index === -1) {
        return state
      }
      newState.splice(index, 1)
      return newState
    default:
      throw new Error(`Unrecognized action type "${type}"`)
  }
}

/**
 * Hook to get notifications state and actions
 *
 * @function useNotificationsReducer
 * @returns {Array} - First element is the state object; second element is an
 *                    object containing the available actions
 */
export const useNotificationsReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  /**
   * Display a notification
   *
   * @function notify
   * @param {Object} data
   * @param {string} data.theme - Display theme to use (info, success, warning,
   *                              error)
   * @param {(string|Object)} data.message - Message to display; either a plain
   *                                         string or props ready for
   *                                         react-intl's FormattedMessage
   *                                         component
   * @param {function} [data.onClick] - Action to perform on notification click
   * @returns {string} - Randomly generated ID of the notification, which can be
   *                     passed to the `dismiss` function
   */
  const notify = useCallback(({ theme, message, onClick = null }) => {
    const id = Math.random().toString(36).substring(2)
    dispatch({
      type: 'notify',
      data: {
        id,
        theme,
        message,
        onClick,
      },
    })
    return id
  }, [])

  /**
   * Display an info notification
   *
   * Convenience method to call `notify` with `theme` set to `info`.
   *
   * @see notify
   *
   * @function info
   * @param {Object} data
   * @returns {string}
   */
  const info = useCallback((options) => notify({ theme: 'info', ...options }), [notify])

  /**
   * Display a success notification
   *
   * Convenience method to call `notify` with `theme` set to `success`.
   *
   * @see notify
   *
   * @function success
   * @param {Object} data
   * @returns {string}
   */
  const success = useCallback((options) => notify({ theme: 'success', ...options }), [notify])

  /**
   * Display a warning notification
   *
   * Convenience method to call `notify` with `theme` set to `warning`.
   *
   * @see notify
   *
   * @function warning
   * @param {Object} data
   * @returns {string}
   */
  const warning = useCallback((options) => notify({ theme: 'warning', ...options }), [notify])

  /**
   * Display an error notification
   *
   * Convenience method to call `notify` with `theme` set to `error`.
   *
   * @see notify
   *
   * @function error
   * @param {Object} data
   * @returns {string}
   */
  const error = useCallback((options) => notify({ theme: 'error', ...options }), [notify])

  /**
   * Dismiss a notification
   *
   * @function dismiss
   * @param {string} id - ID of the notification
   * @returns {void}
   */
  const dismiss = useCallback((id) => {
    dispatch({
      type: 'dismiss',
      data: {
        id,
      },
    })
  }, [])

  /**
   * Time a notification out
   *
   * This currently does exactly the same thing as `dismiss`, but this may
   * change in future.
   *
   * @function timeout
   * @param {string} id - ID of the notification
   * @returns {void}
   */
  const timeout = useCallback((id) => {
    dispatch({
      type: 'timeout',
      data: {
        id,
      },
    })
  }, [])

  return [
    state,
    {
      notify,
      success,
      info,
      warning,
      error,
      dismiss,
      timeout,
    },
  ]
}

/**
 * The notifications context; this should usually be used via the hook
 * @see useNotifications
 */
export const NotificationsContext = createContext([initialState, {}])
NotificationsContext.displayName = 'NotificationsContext'

/**
 * Hook to use the auth context, which will contain the output of useAuthReducer
 * above
 *
 * @function useNotifications
 * @see useNotificationsReducer
 */
export function useNotifications() {
  return useContext(NotificationsContext)
}
