import React from 'react'
import cn from 'classnames'
import { Modal as BoostrapModal } from 'react-bootstrap'
import styles from './styles.module.scss'

export const Modal = ({ isOpen, closeButton = true, close, dialogClassName, backdropClassName, title, body, footer, ...props }) => (
  <BoostrapModal
    dialogClassName={cn(styles.modalDialog, dialogClassName)}
    backdropClassName={cn(styles.modalBackdrop, backdropClassName)}
    show={isOpen}
    onHide={close}
    centered
    restoreFocus
    autoFocus
    {...props}
  >
    {title && (
      <BoostrapModal.Header closeButton={closeButton}>
        <BoostrapModal.Title>{title}</BoostrapModal.Title>
      </BoostrapModal.Header>
    )}
    {body && <BoostrapModal.Body>{body}</BoostrapModal.Body>}
    {footer && <BoostrapModal.Footer>{footer}</BoostrapModal.Footer>}
  </BoostrapModal>
)

export default Modal
