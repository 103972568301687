import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col } from 'react-bootstrap'
import zxcvbn from 'zxcvbn'

import { Row } from '../../components'
import { TextField } from '../field'
import { requiredValidations } from '../validations'

const Account = ({ user, formHooks }) => {
  const { register: registerField, errors } = formHooks
  const { formatMessage } = useIntl()
  return (
    <Row>
      <Col md={6}>
        <TextField
          id="email"
          name="email"
          label={<FormattedMessage id="inputs.labels.email" defaultMessage="Email" />}
          errors={errors.email}
          testId="email"
          fieldRef={registerField(requiredValidations)}
          placeholder={formatMessage({ id: 'inputs.placeholders.email', defaultMessage: 'Enter your email' })}
          defaultValue={user.email}
          smallLabel
          boldLabel
          required
        />
        <TextField
          id="oldPassword"
          name="oldPassword"
          label={<FormattedMessage id="inputs.labels.password" defaultMessage="Password" />}
          errors={errors.oldPassword}
          testId="oldPassword"
          fieldRef={registerField()}
          placeholder={formatMessage({ id: 'inputs.placeholders.password', defaultMessage: 'Password' })}
          smallLabel
          boldLabel
        />
        <TextField
          id="newPassword"
          name="newPassword"
          label={<FormattedMessage id="inputs.labels.newPassword" defaultMessage="New Password" />}
          errors={errors.newPassword}
          testId="newPassword"
          fieldRef={registerField({
            validate: (value) => {
              const { score } = zxcvbn(value)
              if (value && score < 3)
                return <FormattedMessage id="errors.passwordTooWeak" defaultMessage="Password is too weak" />
              return true
            },
          })}
          placeholder={formatMessage({ id: 'inputs.placeholders.password', defaultMessage: 'Password' })}
          smallLabel
          boldLabel
        />
      </Col>
    </Row>
  )
}

export default Account
