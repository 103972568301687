import React, { useState, createContext, useContext, useEffect } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import messages_en from './translations/en.json'
import messages_fr from './translations/fr.json'

const messages = {
  en: messages_en,
  fr: messages_fr,
}

const LOCAL_STORAGE_KEY = 'LS_locale'

export const SUPPORTED_LOCALES = ['en', 'fr']

// Context to control react-intl
const IntlContext = createContext({ locale: 'en', setLocale: () => {} })

export const IntlContextProvider = ({ children }) => {
  const langParam = useLangParam()
  const localStorageSetting = getLocalStorageSetting()
  const browserLanguageSetting = getBrowserLanguageSetting()
  // Local storage prioritizes over browser setting
  const [locale, setLocale] = useState(langParam || localStorageSetting || browserLanguageSetting || 'en')

  useEffect(() => {
    if (locale) {
      localStorage.setItem(LOCAL_STORAGE_KEY, locale)
    }
  }, [locale])

  return (
    <IntlContext.Provider value={{ locale, setLocale }}>
      <ReactIntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  )
}

// Hook to control react-intl
export const useLocale = () => {
  return useContext(IntlContext)
}

export const getBrowserLanguageSetting = () => {
  const { language = '' } = navigator
  const setting = language.substring(0, 2)
  if (SUPPORTED_LOCALES.includes(setting)) {
    return setting
  }
}

export const getLocalStorageSetting = () => {
  const setting = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (SUPPORTED_LOCALES.includes(setting)) {
    return setting
  }
}

// Checks if lang param is present
export const useLangParam = () => {
  const { search } = useLocation()
  if (!search) return undefined
  const { lang } = queryString.parse(search)
  if (!SUPPORTED_LOCALES.includes(lang)) return undefined
  return lang
}
