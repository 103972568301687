import React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { Button } from '../../components'
import { mutations } from '../../api'
import { requiredValidations, emailValidations } from '../validations'
import { TextField, SelectField } from '../field'

import styles from '../form.module.scss'

const InviteAFriendForm = ({ currentUser, programs, afterSubmit }) => {
  const { register: registerField, handleSubmit, errors } = useForm()
  const [inviteAFriend] = useMutation(mutations.programs.inviteAFriend)
  const { formatMessage } = useIntl()

  const inviteAFriendName = (name) => {
    if (currentUser && currentUser.firstName) return `${currentUser.firstName} ${currentUser.lastName}`
    return name
  }

  const onSubmit = async ({ name, friendsEmail, programId }) => {
    try {
      await inviteAFriend({
        variables: {
          name: inviteAFriendName(name),
          friendsEmail,
          programId: Number(programId),
        },
      })
      afterSubmit(true)
    } catch (error) {
      console.log(error)
      afterSubmit(false)
    }
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      {!currentUser && (
        <TextField
          id="name"
          name="name"
          label={<FormattedMessage id="inputs.labels.name" defaultMessage="Your Name" />}
          required={true}
          errors={errors.name}
          testId="name"
          fieldRef={registerField(requiredValidations)}
        />
      )}
      <TextField
        id="email"
        name="friendsEmail"
        label={<FormattedMessage id="inputs.labels.friendsEmail" defaultMessage="Your Friend's Email" />}
        required={true}
        errors={errors.friendsEmail}
        testId="email"
        fieldRef={registerField(emailValidations)}
      />
      <SelectField
        id="program"
        name="programId"
        label={<FormattedMessage id="inputs.labels.program" defaultMessage="Program" />}
        required={true}
        errors={errors.programId}
        testId="program"
        fieldRef={registerField(requiredValidations)}
        defaultOption={formatMessage({
          id: 'inputs.placeholders.selectProgram',
          defaultMessage: 'Please select a program',
        })}
        options={programs.map((program) => ({ value: program.id, label: program.name }))}
      />
      <Button type="submit" variant="primary" size="sm" className={styles.formButton}>
        <FormattedMessage id="cta.invite" defaultMessage="Invite" />
      </Button>
    </Form>
  )
}

export default InviteAFriendForm
