import React, { useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Spinner, Form } from 'react-bootstrap'
import kebabCase from 'lodash/kebabCase'
import shuffle from 'lodash/shuffle'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/client'
import DOMPurify from 'dompurify'
import cn from 'classnames'

import { Button, NarrowContainer } from '../../components'
import { mutations } from '../../api'
import { requiredValidations } from '../validations'
import { useNotifications } from '../../notifications'
import { ScaleField, TextField } from '../field'
import { track } from '../../analytics'

import styles from '../form.module.scss'

const AssessmentResponseForm = ({ enrollment, assessment, type, afterSubmit }) => {
  const {
    register: registerField,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm()
  const [, { error: notifyError, success: notifySuccess }] = useNotifications()
  const [answerAssessment] = useMutation(mutations.enrolledPrograms.answerAssessment)

  const { sections } = assessment

  const trackingContext = {
    enrollmentId: enrollment.id,
    programId: enrollment.program.id,
    programTitle: enrollment.program.name,
  }
  useEffect(() => {
    track(type === 'pre' ? 'PRE_ASSESSMENT_STARTED' : 'POST_ASSESSMENT_STARTED', trackingContext)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = async (fields) => {
    try {
      await answerAssessment({
        variables: {
          enrollmentId: enrollment.id,
          type: type,
          answers: Object.entries(fields).map(([key, value]) => ({
            questionId: key.split('-').pop(),
            value,
          })),
        },
      })
      notifySuccess({
        message: {
          id: 'phrase.assessmentSubmitted',
          defaultMessage: 'Submission Received!',
        },
      })
      afterSubmit(true)
      track(type === 'pre' ? 'PRE_ASSESSMENT_COMPLETED' : 'POST_ASSESSMENT_COMPLETED', trackingContext)
    } catch (error) {
      console.error(error)
      notifyError({
        message: {
          id: 'errors.internalServerError',
          defaultMessage: 'There was a problem processing your request, please try again later.',
        },
      })
      afterSubmit(false)
    }
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className={styles.assessment}>
      {sections.map((section) => (
        <AssessmentSection section={section} key={section.id} errors={errors} registerField={registerField} />
      ))}

      <Button type="submit" variant="primary" disabled={isSubmitting} className={styles.formButton}>
        {isSubmitting ? (
          <>
            <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          <FormattedMessage id="cta.submit" defaultMessage="Submit" />
        )}
      </Button>
    </Form>
  )
}

const AssessmentSection = ({ section, errors, registerField }) => {
  const { title, legend, randomize, questions, reverseScoring } = section

  // Randomize or sort questions based on order
  const orderedQuestions = useMemo(() => (randomize ? shuffle(questions) : questions), [questions, randomize])

  return (
    <section>
      <hr />
      {title && <h2 className="h3">{title}</h2>}
      {legend && (
        <div className={cn(styles.scaleContainer, 'mb-4')}>
          <div className={styles.scale}>
            <NarrowContainer
              className={styles.scaleInner}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(legend) }}
            />
          </div>
        </div>
      )}
      {orderedQuestions.map((question) =>
        question.type === 'scale' ? (
          <ScaleField
            key={question.id}
            id={kebabCase(question.title)}
            name={`assessment-question-${question.id}`}
            label={question.title}
            errors={errors[`assessment-question-${question.id}`]}
            reverseScoring={reverseScoring}
            scale={{
              start: question.scaleStart,
              end: question.scaleEnd,
              step: question.scaleStep,
            }}
            fieldRef={registerField(requiredValidations)}
          />
        ) : (
          <TextField
            key={question.id}
            id={kebabCase(question.title)}
            name={`assessment-question-${question.id}`}
            label={question.title}
            errors={errors[`assessment-question-${question.id}`]}
            fieldRef={registerField()}
            textArea={question.type === 'longTextInput'}
          />
        )
      )}
    </section>
  )
}

export default AssessmentResponseForm
