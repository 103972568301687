import jwt_decode from 'jwt-decode'

export const AUTH_TOKEN_NAME = 'learning_snippets_token'

/**
 * Get the remaining time in seconds of a token
 *
 * @function tokenTtl
 * @param {string} token - Token to inspect
 * @returns {number} - Seconds from now to expiry
 */
export function ttl(token) {
  if (!token) {
    return 0
  }

  const payload = jwt_decode(token)
  const now = new Date().getTime()
  return Math.floor(payload.exp - now / 1000)
}

/**
 * Get the token from storage, return null if token is expired
 *
 * @function get
 * @returns {string?} - Token if valid
 */
export function get() {
  let token = localStorage.getItem(AUTH_TOKEN_NAME)
  // Automatically remove token if it's expired
  if (token) {
    try {
      // Will throw an error if token is invalid
      const decodedToken = jwt_decode(token)
      var currentTime = Math.floor(new Date().getTime() / 1000)
      // Remove token from storage if it's past the expiry time
      if (currentTime > decodedToken.exp) {
        throw new Error('Token expired')
      }
    } catch (error) {
      token = null
      localStorage.removeItem(AUTH_TOKEN_NAME)
    }
  }
  return token
}

/**
 * Remove the token from storage
 *
 * @function remove
 * @returns {void}
 */
export function remove() {
  localStorage.removeItem(AUTH_TOKEN_NAME)
}

/**
 * Set a token in storage
 *
 * @function set
 * @param {string} token - Token to set
 * @returns {void}
 */
export function set(token) {
  localStorage.setItem(AUTH_TOKEN_NAME, token)
}
