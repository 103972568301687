import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet-async'
import { Redirect } from 'react-router-dom'
import qs from 'qs'

import { SignupForm } from '../forms'
import { NarrowContainer, LoadingSpinner } from '../components'
import { queries } from '../api'
import { paths } from '../router'

const HubspotTrackingCode = () => {
  return (
    <Helmet>
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8152648.js"></script>
    </Helmet>
  )
}

const Signup = () => {
  const [hasSignedUp, setHasSignedUp] = useState()
  const { programId } = qs.parse(window.location.search.slice(1))
  const id = Number(programId)
  const { loading, data } = useQuery(queries.programs.getProgramPreview, {
    variables: { id },
    skip: !id,
  })

  if (!programId) return <Redirect to={paths.auth.signupTrial} />

  if (programId && loading) return <LoadingSpinner />
  return (
    <NarrowContainer>
      {hasSignedUp ? (
        <>
          <h1>
            <FormattedMessage id="phrase.registration.success" defaultMessage="Thanks for signing up!" />
          </h1>
          <p>
            <FormattedMessage
              id="phrase.registration.informationToBeDelivered"
              defaultMessage="Your login information will be delivered to your inbox shortly."
            />
          </p>
          <p>
            <FormattedMessage
              id="phrase.registration.checkSpamFolder"
              defaultMessage="Be sure to check your spam folder just in case the email was diverted."
            />
          </p>
        </>
      ) : (
        <>
          {data?.getProgramPreview?.signUpIntro ? (
            <div dangerouslySetInnerHTML={{ __html: data.getProgramPreview.signUpIntro }} />
          ) : (
            <h1>
              <FormattedMessage id="headings.signUp" defaultMessage="Sign up. It's free." />
            </h1>
          )}
          <SignupForm afterSignup={() => setHasSignedUp(true)} />
        </>
      )}
      {process.env.NODE_ENV === 'production' && <HubspotTrackingCode />}
    </NarrowContainer>
  )
}

export default Signup
