import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileAlt, faExternalLinkAlt, faVideo, faTimes } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import Modal from 'react-modal'

import styles from './snippet-resources.module.scss'
import { NarrowContainer, Button } from '../'
import { track } from '../../analytics'

const icons = { file: faFileAlt, pdf: faFilePdf, external: faExternalLinkAlt, video: faVideo }

const SnippetResources = ({ heading, resources = [], trackingContext }) => (
  <NarrowContainer>
    <h3 className={styles.title}>{heading}</h3>
    {resources.map((resource, index) => (
      <SnippetResource key={index} number={index + 1} {...resource} trackingContext={trackingContext} />
    ))}
  </NarrowContainer>
)

const SnippetResource = ({ number, title, description, url, trackingContext }) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false)

  let resourceType = 'external'
  if (url.includes('youtube.com')) {
    resourceType = 'video'
  } else if (url.slice(-4) === '.pdf') {
    resourceType = 'pdf'
  }

  const trackGoDeeperClick = (e) => {
    track('GO_DEEPER', { resourceTitle: title, resourceNumber: number, ...trackingContext })
    if (resourceType === 'video') {
      e.preventDefault()
      setVideoModalOpen(true)
    }
  }

  return (
    <>
      <Button
        variant="grow"
        className={styles.resource}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={trackGoDeeperClick}
      >
        <div className={styles.content}>
          <div className={styles.icon}>
            <FontAwesomeIcon icon={icons[resourceType] || faExternalLinkAlt} />
          </div>
          <div className={styles.text}>
            <span className={styles.resourceTitle}>{title}</span>
            <p>{description}</p>
          </div>
        </div>
      </Button>
      <Modal
        isOpen={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        onRequestClose={() => setVideoModalOpen(false)}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        overlayClassName={styles.modalOverlay}
        className={styles.modal}
        appElement={document.querySelector('#app')}
      >
        <button className={styles.closeModal} onClick={() => setVideoModalOpen(false)}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
        <div className={styles.videoContainer}>
          <ReactPlayer playing={videoModalOpen} url={url} />
        </div>
      </Modal>
    </>
  )
}

export default SnippetResources
