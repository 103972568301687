import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

const { REACT_APP_LOGROCKET_INIT } = process.env

if (REACT_APP_LOGROCKET_INIT) {
  // Setep LogRocket for client session - https://docs.logrocket.com/docs/quickstart
  LogRocket.init(REACT_APP_LOGROCKET_INIT)
  // Enable react-specific component tracking
  setupLogRocketReact(LogRocket)
}

// Associate Logrocket session with user
export const recordLogrocketUser = (data) => {
  if (REACT_APP_LOGROCKET_INIT) {
    LogRocket.identify(data.id, {
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
    })
  }
}
