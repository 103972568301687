import gql from 'graphql-tag'

export const signup = gql`
  mutation(
    $email: String!
    $firstName: String
    $lastName: String
    $role: Role
    $trial: Boolean
    $programId: ID
    $locale: Locale
    $recaptchaToken: String!
  ) {
    signup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      role: $role
      trial: $trial
      programId: $programId
      locale: $locale
      recaptchaToken: $recaptchaToken
    ) {
      token
    }
  }
`

export const confirmEmail = gql`
  mutation($token: String!) {
    confirmEmail(token: $token) {
      id
      passwordSet
    }
  }
`
