import { createContext, useContext, useCallback, useEffect, useReducer } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import * as token from './token'
import * as paths from '../router/paths'
import { queries, mutations } from '../api'
import { recordLogrocketUser } from '../logrocket-setup'

export { token }

const initialState = {
  loggedIn: false,
  authLoading: false,
  queryLoading: !!token.get(),
  user: null,
  token: token.get(),
}

/**
 * Reducer for the auth state
 *
 * @function reducer
 * @param {Object} state - Current state object
 * @param {Object} action - Action being performed
 * @param {string} action.type - Action type
 * @param {Object} [action.data] - Data associated with the action
 * @returns {Object} - New state object
 */
function reducer(state, { type, data }) {
  switch (type) {
    case 'authLoading':
      return {
        ...state,
        authLoading: true,
      }
    case 'authDone':
      return {
        ...state,
        authLoading: false,
      }
    case 'queryLoading':
      return {
        ...state,
        queryLoading: true,
      }
    case 'queryDone':
      return {
        ...state,
        queryLoading: false,
      }
    case 'setPassword':
    case 'login':
      token.set(data.token)
      return {
        ...state,
        loggedIn: true,
        token: data.token,
      }
    case 'register':
      return {
        ...state,
        loggedIn: false,
        token: null,
      }
    case 'logout':
      token.remove()
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null,
      }
    case 'userDetails':
      if (!state.token) {
        // User has logged out; discard response
        return state
      }
      // Associate Logrocket session with user
      if (data.id) {
        recordLogrocketUser(data)
      }
      return {
        ...state,
        loggedIn: true,
        user: {
          ...(state.user ?? {}),
          ...data,
        },
      }
    default:
      throw new Error(`Unrecognized action type "${type}"`)
  }
}

// Pull the token from the url (used by the mobile app to auto-log-in in webviews)
const useUrlToken = () => {
  const { pathname, search } = useLocation()
  const history = useHistory()
  const queryVars = queryString.parse(search)

  const removeTokenFromUrl = useCallback(() => {
    let newQueryVars = queryVars
    delete newQueryVars.token
    history.replace({ pathname, search: queryString.stringify(newQueryVars) })
  }, [history, pathname, queryVars])

  return [queryVars?.token, removeTokenFromUrl]
}

/**
 * Hook to get auth state and actions
 *
 * @function useAuthReducer
 * @param {Object} data
 * @param {boolean} data.currentPageIsPrivate - True if the currently-viewed
 *                                              page is private
 * @returns {Array} - First element is the state object; second element is an
 *                    object containing the available actions
 */
export const useAuthReducer = ({ currentPageIsPrivate }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const history = useHistory()
  const client = useApolloClient()
  const [urlToken, removeTokenFromUrl] = useUrlToken()
  const { loading: queryLoading, data: queryResponse, refetch } = useQuery(queries.auth.getCurrentUser, {
    skip: !state.token,
    notifyOnNetworkStatusChange: true,
  })

  // Dispatch new user data when it's received
  useEffect(() => {
    if (queryResponse) {
      dispatch({ type: 'userDetails', data: queryResponse.getCurrentUser })
    }
  }, [queryResponse])

  // Refetch user data from GraphQL endpoint when token changes
  useEffect(() => {
    if (!state.token) {
      return
    }
    refetch()
  }, [refetch, state.token])

  // Log the user in automatically if a token is provided in the initial url
  useEffect(() => {
    if (state.loggedIn) return
    if (urlToken && urlToken !== 'null') {
      dispatch({
        type: 'login',
        data: { token: urlToken },
      })
      removeTokenFromUrl()
    }
  }, [state.loggedIn, urlToken, dispatch, removeTokenFromUrl])

  /**
   * Log the user in
   *
   * @function login
   * @async
   * @param {Object} data
   * @param {string} data.email - Email address
   * @param {string} data.password - Password
   * @returns {Promise<Object>} - Data about the logged-in user
   */
  const login = async ({ email, password }) => {
    dispatch({ type: 'authLoading' })
    const response = await client.mutate({ mutation: mutations.auth.login, variables: { email, password } })
    dispatch({
      type: 'login',
      data: response.data.login,
    })
    dispatch({ type: 'authDone' })
    return response
  }

  /**
   * Log the user out
   *
   * The changes to state are always made immediately, but if the API call is
   * made, the promise is only resolved once that finishes.
   *
   * @function logout
   * @async
   * @param {Object} options
   * @param {boolean} options.skipApi - Pass true to avoid sending the API request
   *                                    which would invalidate the user's token,
   *                                    for example if you know the existing
   *                                    token is invalid and so the call would
   *                                    fail
   * @returns {Promise<null|Object>} - API call response or null if the call
   *                                   wasn't made
   */
  const logout = useCallback(
    async ({ skipApi = false }) => {
      let promise = null

      if (!skipApi) {
        // Trigger API call but don't actually wait for it for the purposes of
        // logging the user out from the front end; other code could await this if
        // necessary for some reason, so it's returned at the end
        promise = client.mutate({ mutation: mutations.auth.logout })
      }

      // Clear GQL cache
      client.clearStore()

      // Navigate to home page if we were on a private route
      if (currentPageIsPrivate) {
        history.push(paths.pages.home)
      }

      dispatch({ type: 'logout' })

      return promise
    },
    [currentPageIsPrivate, history, client]
  )

  /**
   * Register a user
   *
   * @function register
   * @async
   * @param {Object} data
   * @param {string} data.email - Email address
   * @param {string} data.password - Password
   * @param {string} data.fullName - Full name
   * @param {string} data.companyName - Company name
   * @param {string} data.invitationToken - Invitation token
   * @returns {Promise<Object>} - Response with success key plus user data and
   *                              token
   */
  const register = async ({ email, firstName, lastName, programId, role, trial, locale, recaptchaToken }) => {
    dispatch({ type: 'authLoading' })
    const response = await client.mutate({
      mutation: mutations.registration.signup,
      variables: { email, firstName, lastName, programId, role, trial, locale, recaptchaToken },
    })

    dispatch({
      type: 'register',
      data: response.data.signup,
    })
    dispatch({ type: 'authDone' })
  }

  /**
   * Sets the password for a user without one
   *
   * @function setPassword
   * @async
   * @param {Object} data
   * @param {string} data.confirmationToken - Confirmation Token
   * @param {string} data.password - Password
   * @param {string} data.passwordConfirmation - Password Confirmation
   * @returns {Promise<Object>} - Data about the updated user
   */
  const setPassword = async ({ confirmationToken, password, passwordConfirmation }) => {
    dispatch({ type: 'authLoading' })
    const response = await client.mutate({
      mutation: mutations.auth.setPassword,
      variables: { confirmationToken, password, passwordConfirmation },
    })
    dispatch({
      type: 'setPassword',
      data: response.data.setPassword,
    })
    dispatch({ type: 'authDone' })
    return response
  }
  // Capture loading state of GQL query in reducer state
  useEffect(() => {
    if (queryLoading) {
      dispatch({ type: 'queryLoading' })
    } else {
      dispatch({ type: 'queryDone' })
    }
  }, [queryLoading])

  return [
    state,
    {
      login,
      logout,
      register,
      setPassword,
    },
  ]
}

/**
 * The auth context; this should usually be used via the hook
 * @see useAuth
 */
export const AuthContext = createContext([initialState, {}])
AuthContext.displayName = 'AuthContext'

/**
 * Hook to use the auth context, which will contain the output of useAuthReducer
 * above
 *
 * @function useAuth
 * @see useAuthReducer
 */
export function useAuth() {
  return useContext(AuthContext)
}
