import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { FormattedMessage } from 'react-intl'

import ProfileSettings from './profile-settings'
import TextSize from './text-size'
import Notifications from './notifications'
import Account from './account-settings'
import { Button, ButtonRow } from '../../components'
import { mutations, queries } from '../../api'
import { useNotifications } from '../../notifications'

const Profile = ({ user = {} }) => {
  const [, { success: notifySuccess, error: notifyError }] = useNotifications()
  const [updateProfile] = useMutation(mutations.users.updateProfile, {
    refetchQueries: [{ query: queries.users.getCurrentUser }],
  })
  const [emailsEnabled, setEmailsEnabled] = useState(null)
  const [notificationsEnabled, setNotificationsEnabled] = useState(null)
  const [remindersEnabled, setRemindersEnabled] = useState(null)
  const formHooks = useForm()
  const { handleSubmit, setError } = formHooks
  const onSubmit = async (values) => {
    try {
      const variables = {
        ...values,
        subscribed: emailsEnabled,
        notified: notificationsEnabled,
        sendReminders: remindersEnabled,
      }
      await updateProfile({ variables })

      notifySuccess({
        message: {
          id: 'notifications.profileUpdatedSuccess',
          defaultMessage: 'Your profile has been updated.',
        },
      })
    } catch (error) {
      notifyError({
        message: {
          id: 'notifications.profileUpdatedError',
          defaultMessage: 'There was an error updating your profile.',
        },
      })
      if (error?.message.toLowerCase().includes('current password')) {
        setError('oldPassword', {
          type: 'manual',
          message: (
            <FormattedMessage
              id="errors.oldPassword"
              defaultMessage="Enter the current password to set a new password."
            />
          ),
        })
      }
      console.log(error)
    }
  }

  const onToggleEmails = (e) => {
    setEmailsEnabled(e.target.checked)
  }

  const onToggleNotifications = (e) => {
    setNotificationsEnabled(e.target.checked)
  }

  const onToggleReminders = (e) => {
    setRemindersEnabled(e.target.checked)
  }
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <ProfileSettings user={user} formHooks={formHooks} />
      <TextSize user={user} formHooks={formHooks} />
      <h2>
        <FormattedMessage id="headings.notifications" defaultMessage="Notifications" />
      </h2>
      <Notifications
        onToggleEmails={onToggleEmails}
        onToggleNotifications={onToggleNotifications}
        onToggleReminders={onToggleReminders}
        user={user}
        formHooks={formHooks}
      />
      <h2>
        <FormattedMessage id="headings.account" defaultMessage="Account" />
      </h2>
      <Account user={user} formHooks={formHooks} />
      <ButtonRow>
        <Button type="submit" variant="primary" size="sm">
          <FormattedMessage id="cta.save" defaultMessage="Save" />
        </Button>
      </ButtonRow>
    </Form>
  )
}

export default Profile
