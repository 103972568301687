import React, { useCallback, useRef, useEffect } from 'react'
import c from 'classnames'
import { FormattedMessage } from 'react-intl'
import Button from 'react-bootstrap/Button'

import { useNotifications } from '../../notifications'
import { generic as genericErrorMessage } from '../../error-messages'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import styles from './styles.module.scss'

const TIMEOUT_MS = 5e3
const SHOW_COUNT = 2 // Show the latest notifications, at maximum this many

function renderableMessage(message) {
  if (typeof message === 'object' && 'id' in message && 'defaultMessage' in message) {
    return <FormattedMessage {...message} />
  }
  if (message instanceof Error) {
    console.error(message)
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return message.toString()
    }
    return <FormattedMessage {...genericErrorMessage} />
  }
  return message.toString()
}

/**
 * A single toast notification
 *
 * It times itself out, or can be manually dismissed.
 * The timeout is paused when the mouse pointer is over.
 */
const Notification = ({ id, theme, message, onClick, onDismiss, onTimeout }) => {
  const timerStartRef = useRef(null)
  const timerElapsedRef = useRef(0)
  const timerRef = useRef(null)

  const startTimer = useCallback(() => {
    clearTimeout(timerRef.current)
    timerStartRef.current = Date.now()
    timerRef.current = setTimeout(onTimeout, TIMEOUT_MS - timerElapsedRef.current)
  }, [onTimeout])

  const pauseTimer = useCallback(() => {
    clearTimeout(timerRef.current)
    timerElapsedRef.current += Date.now() - timerStartRef.current
  }, [])

  // Start timer on mount
  useEffect(() => {
    startTimer()
    return pauseTimer
  }, [startTimer, pauseTimer])

  return (
    <article
      className={c(styles.notification, styles[theme])}
      onClick={onClick}
      onMouseEnter={pauseTimer}
      onMouseLeave={startTimer}
    >
      <div data-testid="notification-popup" className={styles.content}>
        {renderableMessage(message)}
      </div>
      <div>
        <Button
          variant="unstyled"
          className={styles.dismiss}
          onClick={(event) => {
            // Don't trigger main notification click handler
            event.stopPropagation()
            onDismiss()
          }}
        >
          <FontAwesomeIcon size="lg" icon={faTimes} />
          <div className="sr-only">
            <FormattedMessage id="cta.dismiss" defaultMessage="Dismiss" />
          </div>
        </Button>
      </div>
    </article>
  )
}

/**
 * List of current notifications
 *
 * Intended to be displayed just once on the site.
 */
const Notifications = () => {
  const [notifications, { dismiss, timeout }] = useNotifications()

  return (
    <div className={styles.root}>
      {notifications.slice(0, SHOW_COUNT).map((props) => (
        <Notification
          key={props.id}
          {...props}
          onDismiss={() => {
            dismiss(props.id)
          }}
          onTimeout={() => {
            timeout(props.id)
          }}
        />
      ))}
    </div>
  )
}

export default Notifications
