import React from 'react'
import { FormattedMessage } from 'react-intl'

export const requiredValidations = {
  required: <FormattedMessage id="errors.required" defaultMessage="This field is required" />,
}
export const emailValidations = {
  required: requiredValidations.required,
  pattern: {
    // Stupidly complex regex taken from here: https://blog.mailtrap.io/react-native-email-validation/. If we run into issues, we should remove it.
    value: /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i, // eslint-disable-line no-control-regex
    message: <FormattedMessage id="errors.invalidEmailFormat" defaultMessage="Invalid email format" />,
  },
}
export const passwordValidations = {
  required: <FormattedMessage id="errors.passwordRequired" defaultMessage="Please enter a password" />,
  maxLength: {
    value: 100,
    message: <FormattedMessage id="errors.fieldTooLong" defaultMessage="Field is too long" />,
  },
}
