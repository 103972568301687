import React from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import { Button } from '../../components'
import { useAuth } from '../../auth'
import { Link, paths } from '../../router'
import { emailValidations, passwordValidations } from '../validations'
import { TextField, PasswordField } from '../field'

import styles from '../form.module.scss'

const LoginForm = () => {
  const [{ loading }, { login }] = useAuth()
  const { register: registerField, handleSubmit, errors, clearErrors, setError } = useForm()

  const onSubmit = async (data) => {
    try {
      await login({
        email: data.email,
        password: data.password,
      })
    } catch (error) {
      if (error && error.message && error.message.toLowerCase().includes('not activated')) {
        setError('submit', {
          message: (
            <FormattedMessage
              id="errors.userNotActivated"
              defaultMessage="It looks like your account hasn't been activated yet. We just sent you an email with a link to set a password"
            />
          ),
        })
      }
      // If error from GQL includes 'invalid' - invalid email or password error
      else if (error && error.message && error.message.toLowerCase().includes('invalid')) {
        setError('submit', {
          message: <FormattedMessage id="errors.invalidLogin" defaultMessage="Your email or password is incorrect" />,
        })
      } else {
        setError('submit', {
          message: (
            <FormattedMessage
              id="errors.internalServerError"
              defaultMessage="There was a problem processing your request, please try again later."
            />
          ),
        })
      }
    }
  }
  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <TextField
        id="email"
        name="email"
        label={<FormattedMessage id="inputs.labels.email" defaultMessage="Email" />}
        errors={errors.email}
        testId="email"
        fieldRef={registerField(emailValidations)}
      />
      <PasswordField
        id="password"
        name="password"
        label={<FormattedMessage id="inputs.labels.password" defaultMessage="Password" />}
        errors={errors.password}
        testId="password"
        fieldRef={registerField(passwordValidations)}
      />
      {/* Clear errors so the user can still submit if there is an internal server error */}
      <Button
        type="submit"
        variant="primary"
        size="sm"
        disabled={loading}
        className={styles.formButton}
        onClick={() => errors.submit && clearErrors()}
      >
        {loading ? (
          <>
            <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          <FormattedMessage id="cta.logIn" defaultMessage="Log In" />
        )}
      </Button>
      {errors.submit && <div className={styles.errorAlertBox}>{errors.submit.message}</div>}
      <Link to={paths.auth.forgotPassword}>
        <FormattedMessage id="cta.forgotPassword" defaultMessage="Forgot Password" />
      </Link>
    </Form>
  )
}

export default LoginForm
