import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, LoadingSpinner } from '../../components'
import { ProfileForm } from '../../forms'
import { useAuth } from '../../auth'

const Profile = () => {
  const [{ queryLoading, user }] = useAuth()

  if (queryLoading) return <LoadingSpinner />

  return (
    <main id="main-content">
      <Container>
        <h1>
          <FormattedMessage id="titles.pages.profile" defaultMessage="My Profile" />
        </h1>
        <ProfileForm user={user} />
      </Container>
    </main>
  )
}

export default Profile
