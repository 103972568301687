import React from 'react'
import cn from 'classnames'
import { Row as BootstrapRow } from 'react-bootstrap'
import styles from './row.module.scss'

const Row = (props) => <BootstrapRow {...props} />

export const ButtonRow = ({ className, ...rest }) => <div className={cn(className, styles.buttonRow)} {...rest} />

export { Col } from 'react-bootstrap'
export default Row
