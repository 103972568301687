import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import { queries } from '../../api'
import { NarrowContainer, LoadingSpinner } from '../../components'
import { InviteAFriendForm } from '../../forms'

const InviteAFriend = () => {
  const [invited, setInvited] = useState()
  const { loading, data } = useQuery(queries.programs.getTrialPrograms)
  const { loading: userLoading, data: userData } = useQuery(queries.users.getCurrentUser)
  const { formatMessage } = useIntl()
  if (loading || userLoading) return <LoadingSpinner />

  if (invited) {
    return (
      <NarrowContainer>
        <main>
          <h1>
            <FormattedMessage id="headings.inviteYourFriends" defaultMessage="Invite Your Friends" />
          </h1>
          <p>
            <FormattedMessage
              id="phrase.inviteYourFriends.success"
              defaultMessage="Your friend should be receiving their invitation soon."
            />
          </p>
        </main>
      </NarrowContainer>
    )
  }

  if (!data)
    return (
      <NarrowContainer>
        <main>
          <h1>
            <FormattedMessage id="headings.inviteYourFriends" defaultMessage="Invite Your Friends" />
          </h1>
          <p>
            <FormattedMessage
              id="phrase.inviteYourFriends.noneAvailable"
              defaultMessage="There are no trial programs currently available. Please check back later."
            />
          </p>
        </main>
      </NarrowContainer>
    )
  const { getTrialPrograms: programs } = data
  const currentUser = userData?.getCurrentUser
  const topic = formatMessage(
    {
      id: 'phrase.inviteYourFriends.topic',
      defaultMessage: 'Anti-Black Racism at Work Snippets',
    },
    { i: (text) => <i>{text}</i> }
  )
  return (
    <NarrowContainer>
      <main>
        <FormattedMessage id="headings.inviteYourFriends" defaultMessage="Invite Your Friends" />
        {invited === false && (
          <p>
            <FormattedMessage
              id="errors.inviteYourFriends"
              defaultMessage="There was an error inviting your friend. Please try again."
            />
          </p>
        )}
        <p>
          <FormattedMessage
            id="phrase.inviteYourFriends.description"
            defaultMessage="Fill out the fields below to share {topic} with a colleague or friend."
            values={{
              topic,
            }}
          />
        </p>
        <InviteAFriendForm programs={programs} currentUser={currentUser} afterSubmit={setInvited} />
      </main>
    </NarrowContainer>
  )
}

export default InviteAFriend
