import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from '@apollo/client'
import DOMPurify from 'dompurify'
import cn from 'classnames'
import { LoadingSpinner, NotFound, Table, Row, Col, Button, DashboardCard, BarChart } from '../../components'
import { useParams } from '../../router'
import { queries } from '../../api'
import { useAccounts } from './dashboard'
import styles from './program-stats.module.scss'

export const ProgramStats = () => {
  const { accountId, programSlug } = useParams()
  const { formatMessage } = useIntl()
  // Only owners can see pre survey completions
  const { accounts } = useAccounts()
  const account = accounts.find((account) => account.id === accountId)
  const {
    data: {
      getAccountProgramStats: {
        program = {},
        schedule = {},
        learnerStats = {},
        engagementStats = [],
        goDeeperStats = [],
        benchmarkStats = {},
      } = {},
    } = {},
    loading,
    error,
  } = useQuery(queries.account.getAccountProgramStats, {
    variables: {
      accountId,
      programSlug,
    },
    // TODO: use apollo cache
    fetchPolicy: 'network-only',
  })
  if (loading) return <LoadingSpinner />
  if (!program || error) return <NotFound />

  const currentDate = new Date().toISOString()
  const showBenchmarkStats = benchmarkStats && currentDate >= schedule.completedAt

  const {
    learnerEnrollments,
    learnerPreAssessments,
    learnerStartedSnippets,
    learnerProgramCompletions,
    learnerPostAssessments,
    snippetAverageTime,
  } = learnerStats

  return (
    <div>
      <div className={styles.statsPageHeader}>
        <div>
          <h1>{account?.name}</h1>
          <h2>{program.name}</h2>
        </div>
        <PrintButton />
      </div>
      {account?.programNotes && (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(account?.programNotes[programSlug]) }} />
      )}
      <hr />
      <h2>
        <FormattedMessage id="headings.overviewStats" defaultMessage="Overview Stats" />
      </h2>
      <Row className="mb-5 mt-3">
        <Col md={4} className="mb-3">
          <DashboardCard
            title={learnerEnrollments}
            subtitle={formatMessage({
              id: 'phrase.dashboard.learnersCurrentlyEnrolled',
              defaultMessage: 'Learners Currently Enrolled in Program',
            })}
          />
        </Col>
        <Col md={4} className="mb-3">
          <DashboardCard
            title={`${calculatePercentage(learnerStartedSnippets, learnerEnrollments)}%`}
            subtitle={formatMessage(
              {
                id: 'phrase.dashboard.learnersStartedSnippets',
                defaultMessage: 'Enrolled Learners Who Have Started Snippets',
              },
              { i: (text) => <i>{text}</i> }
            )}
            caption={`${learnerStartedSnippets} ${formatMessage({
              id: 'phrase.dashboard.learnerTotal',
              defaultMessage: 'Learner(s) Total',
            })}`}
          />
        </Col>
        <Col md={4} className="mb-3">
          <DashboardCard
            title={`${calculatePercentage(learnerProgramCompletions, learnerEnrollments)}%`}
            subtitle={formatMessage({
              id: 'phrase.dashboard.learnersCompletedProgram',
              defaultMessage: 'Enrolled Learners Who Have Completed Entire Program',
            })}
            caption={`${learnerProgramCompletions} ${formatMessage({
              id: 'phrase.dashboard.learnerTotal',
              defaultMessage: 'Learner(s) Total',
            })}`}
          />
        </Col>
      </Row>
      <h3>
        <FormattedMessage id="phrase.dashboard.organizationCompare" defaultMessage="How Does My Cohort Compare?" />
      </h3>
      <ComparisonBarChart
        learnerEnrollments={learnerEnrollments}
        learnerPreAssessments={learnerPreAssessments}
        learnerStartedSnippets={learnerStartedSnippets}
        learnerProgramCompletions={learnerProgramCompletions}
        learnerPostAssessments={learnerPostAssessments}
        benchmarkStats={benchmarkStats}
        showBenchmarkStats={showBenchmarkStats}
      />
      <hr className={styles.statsDivider} />
      <h2 className="pt-3 pb-3">
        <FormattedMessage id="phrase.dashboard.engagement" defaultMessage="Engagement" />
      </h2>
      <EngagementTable
        program={program}
        schedule={schedule}
        engagementStats={engagementStats}
        learnerEnrollments={learnerEnrollments}
        learnerPreAssessments={learnerPreAssessments}
        learnerPostAssessments={learnerPostAssessments}
        benchmarkStats={benchmarkStats}
        showBenchmarkStats={showBenchmarkStats}
      />
      <SnippetTimeSpent
        snippetAverageTime={snippetAverageTime}
        benchmarkStats={benchmarkStats}
        showBenchmarkStats={showBenchmarkStats}
      />
      <hr className={styles.statsDivider} />
      <h2 className="pt-3 pb-3">
        <FormattedMessage id="phrase.dashboard.goDeeperStats" defaultMessage="Deeper Learning" />
      </h2>
      <GoDeeperTable
        goDeeperStats={goDeeperStats}
        benchmarkStats={benchmarkStats}
        showBenchmarkStats={showBenchmarkStats}
      />
    </div>
  )
}

const PrintButton = () => {
  const openPrintDialog = () => {
    window && window.print()
  }
  return (
    <Button className={styles.printButton} onClick={openPrintDialog}>
      <FormattedMessage id="stats.dashboard.print" defaultMessage="Print" />
    </Button>
  )
}

const ComparisonBarChart = ({
  learnerEnrollments,
  learnerPreAssessments,
  learnerStartedSnippets,
  learnerProgramCompletions,
  learnerPostAssessments,
  benchmarkStats,
  showBenchmarkStats,
}) => {
  const { formatMessage } = useIntl()

  const preAssessmentValues = [learnerPreAssessments]
  const startedSnippetValues = [learnerStartedSnippets]
  const programCompletionValues = [learnerProgramCompletions]
  const postAssessmentValues = [learnerPostAssessments]
  const legend = [
    formatMessage({
      id: 'stats.dashboard.yourOrganization',
      defaultMessage: 'This Cohort',
    }),
  ]
  // Add benchmark stats data
  if (showBenchmarkStats) {
    preAssessmentValues.push(benchmarkStats.preAssessmentsCompleted)
    startedSnippetValues.push(benchmarkStats.snippetsStarted)
    programCompletionValues.push(benchmarkStats.snippetsCompleted)
    postAssessmentValues.push(benchmarkStats.postAssessmentsCompleted)
    legend.push(
      formatMessage({
        id: 'stats.dashboard.benchmark',
        defaultMessage: 'Benchmark',
      })
    )
  }
  return (
    <BarChart
      scale={learnerEnrollments}
      data={[
        {
          label: formatMessage(
            { id: 'stats.dashboard.startedSnippets', defaultMessage: 'Started Snippets' },
            { i: (text) => <i>{text}</i> }
          ),
          values: startedSnippetValues,
        },
        {
          label: formatMessage(
            { id: 'stats.dashboard.completedSnippets', defaultMessage: 'Completed All Snippets' },
            { i: (text) => <i>{text}</i> }
          ),
          values: programCompletionValues,
        },
        {
          label: formatMessage({
            id: 'stats.dashboard.completedImpactSurvey',
            defaultMessage: 'Completed Impact Survey',
          }),
          values: postAssessmentValues,
        },
        //Add an extra bar for spacing
        {
          label: '',
          values: [],
        },
      ]}
      legend={legend}
    />
  )
}

const EngagementTable = ({
  program = {},
  schedule = {},
  engagementStats = [],
  learnerEnrollments = 1,
  learnerPreAssessments,
  learnerPostAssessments,
  benchmarkStats,
  showBenchmarkStats,
}) => {
  const { formatMessage } = useIntl()
  // Filter assessments that have been released
  const currentDate = new Date().toISOString()
  const preAssessmentReleased = program.preAssessment && currentDate >= schedule.preAssessment
  const postAssessmentReleased = program.postAssessment && currentDate >= schedule.postAssessment
  // Map engagement stats to table rows
  const rows = engagementStats.map((engagementStat) => [
    engagementStat.snippetTitle,
    <div className={styles.statsTableCell}>
      <span>{engagementStat.completions}</span>
      <ProgressBar value={engagementStat.completions} scale={learnerEnrollments} />
    </div>,
    <span>{engagementStat.optionClicks}</span>,
  ])
  // Add pre assessment to the start, and post assessment to the end
  if (preAssessmentReleased) {
    rows.unshift([
      formatMessage({ id: 'table.rows.ARISE', defaultMessage: 'ARISE' }),
      <span>{learnerPreAssessments}</span>,
      <span>-</span>,
    ])
  }
  if (postAssessmentReleased) {
    rows.push([
      formatMessage({ id: 'table.rows.postAssessment', defaultMessage: 'Impact Survey' }),
      <span>{learnerPostAssessments}</span>,
      <span>-</span>,
    ])
  }
  // Add rows for averages
  // Add completion ratios for each item (completed / learners) and calculate percent based on number of items
  const percentageCompleted = calculatePercentage(
    engagementStats.map((engagementStat) => engagementStat.completions / learnerEnrollments).reduce(sum, 0) +
      learnerPreAssessments / learnerEnrollments +
      learnerPostAssessments / learnerEnrollments,
    engagementStats.length + preAssessmentReleased + postAssessmentReleased // two for pre and post assessment
  )
  rows.push([
    formatMessage({ id: 'table.rows.average', defaultMessage: 'Completion Rate' }),
    <span>{`${percentageCompleted}%`}</span>,
    <span>-</span>,
  ])
  if (
    showBenchmarkStats &&
    benchmarkStats.averageEngagementCompletion &&
    benchmarkStats.timesMultipleOptionsWereExplored
  ) {
    rows.push([
      formatMessage({ id: 'table.rows.benchmark', defaultMessage: 'Benchmark' }),
      <span>{benchmarkStats.averageEngagementCompletion}%</span>,
      <span>{benchmarkStats.timesMultipleOptionsWereExplored}</span>,
    ])
  }
  return (
    <>
      <Table
        headings={[
          `${formatMessage({
            id: 'table.headings.item',
            defaultMessage: 'Item',
          })}*`,
          formatMessage({
            id: 'table.headings.learnersCompleted',
            defaultMessage: 'Completions',
          }),
          `${formatMessage({
            id: 'table.headings.learnersExploredOptions',
            defaultMessage: 'Extra Options Explored (Total)',
          })}†`,
        ]}
        rows={rows}
        size="sm"
        className={cn([styles.statsTable, styles.fixedWidthTable])}
      />
    </>
  )
}

// Progress Bar for Engagement table snippet completions
const ProgressBar = ({ value, scale }) => {
  const percentage = calculatePercentage(value, scale)
  return (
    <div className={styles.progressBar}>
      <div className={styles.progressBarFill} style={{ width: `${percentage}%` }} />
    </div>
  )
}

// Snippet time spent strings
const SnippetTimeSpent = ({ snippetAverageTime = '00:00', benchmarkStats = {}, showBenchmarkStats }) => (
  <div className={styles.snippetTimeSpent}>
    <p className={styles.snippetTimeSpentTitle}>{snippetAverageTime}</p>
    <p className={styles.snippetTimeSpentSubtitle}>
      <FormattedMessage
        id="stats.dashboard.averageTimeSpent"
        defaultMessage="Average Time Spent on Each Snippet (min:sec)"
        values={{ i: (text) => <i>{text}</i> }}
      />
      †
    </p>
    {showBenchmarkStats && benchmarkStats.averageTimeSpentOnSnippet && (
      <p className={styles.snippetTimeSpentCaption}>
        {benchmarkStats.averageTimeSpentOnSnippet}{' '}
        <FormattedMessage id="stats.dashboard.benchmark" defaultMessage="Benchmark" />
      </p>
    )}
  </div>
)

// Go Deeper stats for resource clicks
const GoDeeperTable = ({ goDeeperStats = [], benchmarkStats, showBenchmarkStats }) => {
  const { formatMessage } = useIntl()
  goDeeperStats.sort((a, b) => b.resourceClicks - a.resourceClicks)
  const rows = goDeeperStats.map((goDeeperStat) => [
    goDeeperStat.snippetTitle,
    <a href={goDeeperStat.resourceUrl} target="_blank" rel="noopener noreferrer">
      {goDeeperStat.resourceTitle}
    </a>,
    <span>{goDeeperStat.resourceClicks}</span>,
  ])
  const totalResourceClicks = goDeeperStats.map((goDeeperStat) => goDeeperStat.resourceClicks).reduce(sum, 0)
  rows.push([
    formatMessage({ id: 'table.rows.totalClicks', defaultMessage: 'Total' }),
    ' ',
    <span>{totalResourceClicks}</span>,
  ])

  if (showBenchmarkStats && benchmarkStats.resourceClicks) {
    rows.push([
      formatMessage({ id: 'table.rows.benchmarkAverage', defaultMessage: 'Benchmark Average' }),
      ' ',
      <span>{benchmarkStats.resourceClicks}</span>,
    ])
  }
  return (
    <>
      <Table
        headings={[
          formatMessage(
            { id: 'table.headings.snippetName', defaultMessage: 'Snippet Name' },
            { i: (text) => <i>{text}</i> }
          ),
          formatMessage({ id: 'table.headings.resourceName', defaultMessage: 'Resource Name' }),
          formatMessage({
            id: 'table.headings.resourceClicks',
            defaultMessage: 'Total Link Clicks',
          }),
        ]}
        rows={rows}
        size="sm"
        className={styles.statsTable}
        striped
        bordered
      />
      <p className={styles.statsTableCaption}>
        *{' '}
        <FormattedMessage
          id="phrase.dashboard.onlyReleasedShown"
          defaultMessage="Only items that have been released are shown."
        />
      </p>
      <p className={styles.statsTableCaption}>
        †{' '}
        <FormattedMessage
          id="phrase.dashboard.statsUpdatedWhen"
          defaultMessage="Numbers updated everyday at 3:00am (Eastern). Today's stats are not reflected here."
        />
      </p>
    </>
  )
}

// Calculate percentage of two values
const calculatePercentage = (value, scale) => Math.min(Math.round((value / scale) * 100), 100) || 0

// Sum reducer
const sum = (previousValue, currentValue) => previousValue + currentValue
