import React from 'react'
import { FormattedMessage } from 'react-intl'
import DOMPurify from 'dompurify'
import { NarrowContainer, Button, ButtonRow } from '../'
import styles from './consequences.module.scss'

const Consequences = ({ program, description, onPickAgain, onHappy, hideButtons = false }) => {
  return (
    <NarrowContainer className={styles.container}>
      <h3 className={styles.title}>
        <FormattedMessage id="headings.consequences" defaultMessage="What Happened?" />
      </h3>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
      {!hideButtons && (
        <ButtonRow>
          <Button variant="grow" onClick={onPickAgain} testId="pickAgain">
            <FormattedMessage id="cta.pickAgain" defaultMessage="Pick again" />
          </Button>
          <Button variant="grow" onClick={onHappy} testId="happy">
            <FormattedMessage id="cta.happy" defaultMessage="Happy with my answer" />
          </Button>
        </ButtonRow>
      )}
    </NarrowContainer>
  )
}

export default Consequences
