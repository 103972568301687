import gql from 'graphql-tag'

export const getCurrentUser = gql`
  query {
    getCurrentUser {
      id
      email
      firstName
      lastName
      organizationId
      subscribed
      notified
      sendReminders
      role
      hasDashboard
      profile {
        id
        preferredPronouns
        industry
        company
        fontScale
        address
        companyRole
        locale
      }
      enrollments {
        id
        program {
          id
          slug
          name
        }
      }
    }
  }
`
