import React from 'react'
import { Helmet } from 'react-helmet-async'
import { FormattedMessage, useIntl } from 'react-intl'

import { ResetPasswordForm } from '../forms'
import { useParams } from '../router'
import { NarrowContainer } from '../components'

const ResetPassword = () => {
  const { token } = useParams()
  const { formatMessage } = useIntl()
  const title = {
    id: 'titles.pages.resetPassword',
    defaultMessage: 'Change Password',
  }
  return (
    <NarrowContainer>
      <Helmet title={formatMessage(title)} />
      <h1>
        <FormattedMessage id="headings.changePassword" defaultMessage="Change Password" />
      </h1>
      <ResetPasswordForm token={token} />
    </NarrowContainer>
  )
}

export default ResetPassword
