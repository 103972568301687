import React from 'react'
import DOMPurify from 'dompurify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import styles from './preview-card.module.scss'

const PreviewCard = ({ title, excerpt, completed, cta }) => (
  <div className={styles.previewCard}>
    <div className={styles.titleContainer}>
      <h3>{title}</h3>
      {completed && <FontAwesomeIcon className={styles.icon} size="lg" icon={faCheckCircle} />}
    </div>
    <div className={styles.excerpt} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(excerpt) }} />
    {cta}
    <div className={styles.dottedBorder} />
  </div>
)

export default PreviewCard
