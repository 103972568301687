import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import browserUpdate from 'browser-update'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { ApolloProvider, ApolloConsumer } from '@apollo/client'
import queryString from 'query-string'

import { client } from './api'
import { AuthContext, useAuthReducer } from './auth'
import { IntlContextProvider, useLocale } from './i18n'
import { NotificationsContext, useNotificationsReducer } from './notifications'
import { useIsTabbing } from './hooks/useIsTabbing'
import { Routes } from './router'
import { Header, Footer, Notifications } from './components'
import UserSettings from './user-settings'
import Analytics from './analytics'
import './App.global.scss'
import './logrocket-setup'
import { FormattedMessage } from 'react-intl'

const ScrollToTop = (pathname) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

const App = () => {
  browserUpdate({
    required: {
      e: -1,
      f: -1,
      o: -1,
      s: -1,
      c: -1,
      samsung: -1,
      vivaldi: -1,
    },
    insecure: true,
    unsupported: true,
    style: 'bottom',
    no_permanent_hide: true,
  })
  return (
    <Router>
      <IntlContextProvider>
        <HelmetProvider>
          <Meta />
          <ApolloProvider client={client}>
            <ApolloConsumer>{(client) => <AppWithAuth client={client} />}</ApolloConsumer>
          </ApolloProvider>
        </HelmetProvider>
      </IntlContextProvider>
    </Router>
  )
}
// Odd separation needed so that we can use Apollo in useAuthReducer. Otherwise apollo isn't loaded in time
const AppWithAuth = ({ client }) => {
  useIsTabbing()
  const [isMounted, setIsMounted] = useState(false)
  const auth = useAuthReducer({ currentPageIsPrivate: false })
  const notifications = useNotificationsReducer()
  const { locale } = useLocale()
  useEffect(() => {
    if (isMounted) {
      client.resetStore()
    } else {
      setIsMounted(true)
    }
  }, [locale]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AuthContext.Provider value={auth}>
      <NotificationsContext.Provider value={notifications}>
        <UserSettings />
        <PageWrapper />
      </NotificationsContext.Provider>
    </AuthContext.Provider>
  )
}

const PageWrapper = () => {
  const { pathname, hash, search } = useLocation()

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.tabIndex = -1
          element.scrollIntoView()
          element.focus()
        }
      }, 0)
    }
  }, [pathname, hash]) // do this on route change

  const isEmbedded = useIsEmbedded(search)
  return (
    <>
      <ScrollToTop pathname={pathname} />
      {/* <div className="maintainance-notice">
        <p>
          <FormattedMessage id="phrase.webnotice" />
        </p>
      </div> */}
      {!isEmbedded && <Header backgroundColor="red" />}
      <Notifications />
      <Routes />
      {!isEmbedded && <Footer />}
      <Analytics.PageTracker />
      <Analytics.IdentityTracker />
    </>
  )
}

/**
 * Hook to tell if we should show the embed view (toggled by embed=true in the url)
 *
 * @function useIsEmbedded
 * @returns {Boolean}
 */
const useIsEmbedded = (search) => {
  const queryVars = queryString.parse(search)

  const [isEmbedded, setIsEmbedded] = useState(false)

  useEffect(() => {
    if (queryVars?.embed) setIsEmbedded(true)
  }, [queryVars, setIsEmbedded])

  return isEmbedded
}

const Meta = () => {
  const { locale } = useLocale()
  return <Helmet htmlAttributes={{ lang: locale }} titleTemplate="%s – Learning Snippets" />
}

export default App
