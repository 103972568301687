import React from 'react'
import { FormattedMessage } from 'react-intl'

export const Forbidden = ({ error }) => {
  const title = () => {
    switch (error) {
      case 'email not found':
      default:
        return <FormattedMessage id="headings.forbidden" defaultMessage="Access Forbidden" />
    }
  }
  const message = () => {
    switch (error) {
      case 'email not found':
        return (
          <FormattedMessage
            id="phrase.login.emailNotFound"
            defaultMessage="Sorry, the email you have used is not registered with any account, please contact your account owner to verify your email"
          />
        )
      default:
        return (
          <FormattedMessage
            id="phrase.forbidden"
            defaultMessage="Sorry, your account type does not permit you access to this page."
          />
        )
    }
  }
  return (
    <>
      <h1>{title()}</h1>
      <p>{message()}</p>
    </>
  )
}

export default Forbidden
