import React from 'react'
import { Helmet } from 'react-helmet-async'
import { FormattedMessage, useIntl } from 'react-intl'
import { NarrowContainer } from '../'
import { Redirect, paths, useLocation } from '../../router'
import { useAuth } from '../../auth'
import { useNotifications } from '../../notifications'

const NotFound = () => {
  const [{ authLoading, queryLoading, loggedIn, user }] = useAuth()
  const location = useLocation()
  const { formatMessage } = useIntl()
  const [, { warning: notifyError }] = useNotifications()
  if (loggedIn) {
    notifyError({
      message: {
        id: 'phrase.notFoundRedirect',
        defaultMessage: 'The content you requested could not be found. Please go back and try again.',
      },
    })
    return <Redirect to={{ pathname: paths.snippets.landing, state: { returnTo: location.pathname } }} />
  }
  
  return (
    <NarrowContainer>
      <Helmet title={formatMessage({ id: 'titles.pages.notFound', defaultMessage: 'Not Found' })} />
      <h1>
        <FormattedMessage id="headings.notFound" defaultMessage="Not Found" />
      </h1>
      <p>
        <FormattedMessage
          id="phrase.notFound"
          defaultMessage="The content you requested could not be found. Please go back and try again."
        />
      </p>
    </NarrowContainer>
  )
}

export default NotFound
