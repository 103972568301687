import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@apollo/client'
import DOMPurify from 'dompurify'

import { paths, buildLink, useHistory } from '../../../router'
import { queries } from '../../../api'
import { NarrowContainer, NotFound, LoadingSpinner, Button } from '../../../components'
import { AssessmentResponseForm } from '../../../forms'

const Assessment = ({ type }) => {
  const { programSlug } = useParams()
  const history = useHistory()
  const { loading, data } = useQuery(queries.enrolledPrograms.getAssessment, { variables: { programSlug } })
  if (loading) return <LoadingSpinner />
  if (!data || data.getEnrolledProgram === null) return <NotFound />

  const { getEnrolledProgram: enrollment } = data

  const completed = type === 'post' ? enrollment.postAssessmentComplete : enrollment.preAssessmentComplete
  if (completed)
    return (
      <NarrowContainer>
        <h1>
          <FormattedMessage
            id="headings.assessmentAlreadyCompleted"
            defaultMessage="You have already completed this assessment."
          />
        </h1>
        <p>
          <Button href={buildLink(paths.snippets.program, { programSlug })}>
            <FormattedMessage id="cta.goBack" defaultMessage="Go Back" />
          </Button>
        </p>
      </NarrowContainer>
    )

  if (type === 'post' && !enrollment.completedAt)
    return (
      <NarrowContainer>
        <h1>
          <FormattedMessage
            id="headings.assessmentSnippetsIncomplete"
            defaultMessage="You must complete all of the Snippets before you can fill out the survey."
            values={{ i: (text) => <i>{text}</i> }}
          />
        </h1>
        <p>
          <Button href={buildLink(paths.snippets.program, { programSlug })}>
            <FormattedMessage id="cta.goBack" defaultMessage="Go Back" />
          </Button>
        </p>
      </NarrowContainer>
    )

  // Get either 'preAssessment' or 'postAssessment'
  const assessment = enrollment.program[`${type}Assessment`]
  const { title, introduction } = assessment

  return (
    <main id="main-content">
      <Helmet title={title} />
      <NarrowContainer>
        <h1>{title}</h1>
        <div className="mb-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(introduction) }} />
        <AssessmentResponseForm
          enrollment={enrollment}
          assessment={assessment}
          type={type}
          afterSubmit={(success) => success && history.push(buildLink(paths.snippets.program, { programSlug }))}
        />
      </NarrowContainer>
    </main>
  )
}

export default Assessment
