import React from 'react'
import { Helmet } from 'react-helmet-async'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Col } from 'react-bootstrap'

import { queries } from '../../../api'
import { paths, buildLink } from '../../../router'
import { Container, PreviewCard, Button, NotFound, LoadingSpinner } from '../../../components'

import styles from './program.module.scss'

const TrialProgram = () => {
  const { programSlug } = useParams()
  const { loading, error, data } = useQuery(queries.programs.getTrialProgram, {
    variables: { programSlug },
  })

  if (loading) return <LoadingSpinner />
  if (!data || error) return <NotFound />

  const {
    getTrialProgram: { icon, name, snippets },
  } = data
  const pageTitle = name.replace(/<\/?[^>]+(>|$)/g, ' ')

  return (
    <>
      <Helmet title={pageTitle} />
      <main id="main-content">
        <Container fluid="md">
          <Col className={styles.trialSnippetsContainer} md={8}>
            <div className={styles.titleContainer}>
              <div className={styles.iconContainer}>
                <img className={styles.icon} src={icon} alt={name} aria-hidden="true" />
              </div>
              <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: name }} />
              <div className={styles.dottedBorder} />
            </div>
            {snippets.map((snippet) => (
              <PreviewCard
                key={snippet.id}
                title={snippet.title}
                excerpt={snippet.excerpt}
                completed={false}
                cta={
                  <Button
                    variant="grow"
                    href={buildLink(paths.snippets.trialSnippet, {
                      programSlug: programSlug,
                      snippetSlug: snippet.slug,
                    })}
                    onClick={() => {}}
                    activeClassName="active"
                  >
                    <FormattedMessage
                      id="cta.viewSnippets"
                      defaultMessage="View Snippet"
                      values={{ i: (text) => <i>{text}</i> }}
                    />
                  </Button>
                }
              />
            ))}
          </Col>
        </Container>
      </main>
    </>
  )
}

export default TrialProgram
