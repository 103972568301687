import React from 'react'
import { Button } from '../'
import { FormattedMessage } from 'react-intl'
import { paths, buildLink } from '../../router'
import { Col } from 'react-bootstrap'

import styles from './other-programs-widget.module.scss'

const OtherProgramsWidget = ({ programs }) => (
  <Col md={3} className={styles.programsContainer}>
    <h3>
      <FormattedMessage id="headings.otherSubscribedPrograms" defaultMessage="Program(s) you're subscribed to" />
    </h3>
    {programs.map((program) => (
      <Button
        key={program.id}
        className={styles.singleProgram}
        variant="unstyled grow"
        href={buildLink(paths.snippets.program, { programSlug: program.slug })}
      >
        <img className={styles.icon} src={program.icon} alt={program.name} aria-hidden="true" />
        <h4 dangerouslySetInnerHTML={{ __html: program.name }} />
      </Button>
    ))}
  </Col>
)

export default OtherProgramsWidget
