import React from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import qs from 'qs'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Button } from '../../components'
import { useAuth } from '../../auth'
import { useLocale } from '../../i18n'
import { TextField } from '../field'
import { emailValidations } from '../validations'
import CaptchaWrapper from '../captcha/captcha'

import styles from '../form.module.scss'

const WrappedSignupForm = (props) => (
  <CaptchaWrapper>
    <SignupForm {...props} />
  </CaptchaWrapper>
)

const SignupForm = ({ afterSignup }) => {
  const queryArgs = qs.parse(window.location.search.slice(1))
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { locale } = useLocale()

  const {
    register: registerField,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: queryArgs.email,
    },
  })

  const [, { register }] = useAuth()

  const onSubmit = async (data) => {
    try {
      await register({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        programId: queryArgs.programId,
        role: 'LEARNER',
        trial: false,
        locale,
        recaptchaToken: executeRecaptcha ? await executeRecaptcha() : '',
      })

      afterSignup && afterSignup()
    } catch (error) {
      console.error(error)
      // If error from GQL includes already exists (email exists error)
      if (error && error.message && error.message.toLowerCase().includes('already in use')) {
        setError('email', {
          message: (
            <FormattedMessage
              id="errors.emailExists"
              defaultMessage="An account with this email address already exists"
            />
          ),
        })
      } else {
        setError('submit', {
          message: (
            <FormattedMessage
              id="errors.internalServerError"
              defaultMessage="There was a problem processing your request, please try again later."
            />
          ),
        })
      }
    }
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <TextField
        id="firstName"
        name="firstName"
        testId="firstName"
        label={<FormattedMessage id="inputs.labels.firstName" defaultMessage="First Name" />}
        fieldRef={registerField}
        errors={errors.firstName}
      />
      <TextField
        id="lastName"
        name="lastName"
        testId="lastName"
        label={<FormattedMessage id="inputs.labels.lastName" defaultMessage="Last Name" />}
        fieldRef={registerField}
        errors={errors.lastName}
      />
      <TextField
        id="email"
        name="email"
        testId="email"
        label={<FormattedMessage id="inputs.labels.workEmail" defaultMessage="Work Email" />}
        fieldRef={registerField(emailValidations)}
        errors={errors.email}
        required
      />

      <Button
        type="submit"
        variant="primary"
        size="sm"
        disabled={isSubmitting}
        className={styles.formButton}
        onClick={() => errors.submit && clearErrors()}
      >
        {isSubmitting ? (
          <>
            <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />
            <span className="sr-only">Loading...</span>
          </>
        ) : (
          <FormattedMessage id="cta.signUp" defaultMessage="Sign Up" />
        )}
      </Button>
      {errors.submit && (
        <div>
          <span className={styles.errorMessage}>{errors.submit.message}</span>
        </div>
      )}
    </Form>
  )
}

export default WrappedSignupForm
