import gql from 'graphql-tag'

export const updateProfile = gql`
  mutation(
    $firstName: String
    $lastName: String
    $email: String
    $subscribed: Boolean
    $notified: Boolean
    $sendReminders: Boolean
    $address: String
    $preferredPronouns: String
    $industry: String
    $company: String
    $fontScale: String
    $companyRole: String
    $locale: Locale
    $oldPassword: String
    $newPassword: String
  ) {
    updateProfile(
      firstName: $firstName
      lastName: $lastName
      email: $email
      subscribed: $subscribed
      notified: $notified
      sendReminders: $sendReminders
      address: $address
      preferredPronouns: $preferredPronouns
      industry: $industry
      company: $company
      fontScale: $fontScale
      companyRole: $companyRole
      locale: $locale
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      id
      firstName
      lastName
      profile {
        id
        address
        preferredPronouns
        industry
        company
        fontScale
        companyRole
        locale
      }
    }
  }
`

export const unsubscribe = gql`
  mutation($email: String!) {
    unsubscribe(email: $email)
  }
`

export const activate = gql`
  mutation {
    activate {
      id
      activated
    }
  }
`

export const selfEnrollInProgram = gql`
  mutation($programId: Int) {
    selfEnrollInProgram(programId: $programId) {
      id
      enrollments {
        id
        trial
        userId
        viewedWelcome
        completedAt
        program {
          id
          name
          description
          slug
          icon
          signUpIntro
          welcomeTitle
          welcomeDescription
        }
      }
    }
  }
`

export const requestAdditionalSeats = gql`
  mutation($seats: Int!, $account: AccountInfoInput!) {
    requestAdditionalSeats(seats: $seats, account: $account)
  }
`
