import gql from 'graphql-tag'

export const getProgramSnippet = gql`
  query($snippetSlug: String!, $programSlug: String!) {
    getProgramSnippet(programSlug: $programSlug, snippetSlug: $snippetSlug) {
      snippet {
        id
        slug
        title
        intro
        excerpt
        introReferences
        descriptionReferences
        summary
        difficulty
        question {
          id
          title
          rationale
          options {
            id
            description
            consequences
            score
            rariseStage
            measurementTags
          }
        }
        resources {
          id
          title
          description
          icon
          url
        }
      }
      program {
        id
        name
        slug
        preAssessment {
          id
        }
        locales
      }
      enrollment {
        id
        preAssessmentComplete
      }
    }
  }
`

export const getTrialProgramSnippet = gql`
  query($programSlug: String!, $snippetSlug: String!) {
    getTrialProgramSnippet(programSlug: $programSlug, snippetSlug: $snippetSlug) {
      snippet {
        id
        slug
        title
        intro
        excerpt
        introReferences
        descriptionReferences
        summary
        difficulty
        question {
          title
          rationale
          options {
            id
            description
            consequences
            score
            rariseStage
            measurementTags
          }
        }
        resources {
          id
          title
          description
          icon
          url
        }
      }
      program {
        id
        name
        slug
        trial
      }
    }
  }
`
