import { useParams, useHistory, useLocation, Redirect, Link } from 'react-router-dom'
import queryString from 'query-string'

import Routes from './routes'
import * as paths from './paths'
import buildLink from './build-link'
import PrivateRoute from './private-route'
import { useEffect } from 'react'
import { useState } from 'react'

export { Routes, paths, buildLink, useParams, useHistory, useLocation, PrivateRoute, Redirect, Link }

export const useSearchParams = () => {
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useState(queryString.parse(search))
  useEffect(() => {
    setSearchParams(queryString.parse(search))
  }, [search])
  return searchParams
}
