import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Row, Col } from 'react-bootstrap'
import ProgramLocales from '../../../components/program-locales/program-locales'

import { queries } from '../../../api'
import { paths, buildLink } from '../../../router'
import {
  OtherProgramsWidget,
  Container,
  PreviewCard,
  Button,
  NotFound,
  LoadingSpinner,
  ProgramCertificate,
  AssessmentModal,
} from '../../../components'
import Welcome from './welcome'

import styles from './program.module.scss'

const Program = () => {
  const { programSlug } = useParams()
  const { loading, error, data } = useQuery(queries.programs.getUserPrograms)
  const [preAssessmentModalOpen, setPreAssessmentModalOpen] = useState(false)
  if (loading) return <LoadingSpinner />
  if (!data || error) return <NotFound />

  const {
    me: { enrollments },
  } = data
  const currentEnrollment = enrollments.find((enrollment) => enrollment.program.slug === programSlug)
  if (!currentEnrollment) return <NotFound />

  const {
    program: currentProgram,
    snippets,
    responses,
    preAssessmentComplete,
    postAssessmentComplete,
    completedAt,
    viewedWelcome,
    trial,
  } = currentEnrollment
  const myPrograms = enrollments.map((enrollment) => enrollment.program)
  const pageTitle = currentProgram.name.replace(/<\/?[^>]+(>|$)/g, ' ')
  const isCertificateAvailable = completedAt && !trial
  const isPreAssessmentAvailable = currentProgram.preAssessment && !preAssessmentComplete
  const isPostAssessmentAvailable = currentProgram.postAssessment && completedAt && !postAssessmentComplete

  const orderedSnippets = snippets.slice().reverse() // Show most recent releases first
  const isSnippetComplete = (snippetId) => {
    return responses.find((response) => response.snippetId === snippetId)
  }

  if (!viewedWelcome) {
    return (
      <main>
        <Welcome
          title={currentProgram.welcomeTitle}
          description={currentProgram.welcomeDescription}
          cta={
            isPreAssessmentAvailable ? (
              <Button href={buildLink(paths.snippets.preAssessment, { programSlug })}>
                <FormattedMessage id="cta.takeThePreAssessment" defaultMessage="Take the Assessment Survey now" />
              </Button>
            ) : (
              snippets[0] && (
                <Button href={buildLink(paths.snippets.snippet, { programSlug, snippetSlug: snippets[0].slug })}>
                  <FormattedMessage
                    id="cta.goToFirstSnippet"
                    defaultMessage="See my first Snippet"
                    values={{ i: (text) => <i>{text}</i> }}
                  />
                </Button>
              )
            )
          }
          enrollmentId={currentEnrollment.id}
        />
      </main>
    )
  }

  return (
    <>
      <Helmet title={pageTitle} />
      <main id="main-content">
        <Container fluid="md">
          <Row>
            <OtherProgramsWidget programs={myPrograms} />
            <Col className={styles.snippetsContainer} md={8}>
              <div className={styles.titleContainer}>
                <div className={styles.iconContainer}>
                  <img className={styles.icon} src={currentProgram.icon} alt={currentProgram.name} aria-hidden="true" />
                </div>
                <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: currentProgram.name }} />
                <ProgramLocales styles={styles.translations} locales={currentProgram.locales} />
                <div className={styles.dottedBorder} />
              </div>
              {isCertificateAvailable && <ProgramCertificate program={currentProgram} enrollment={currentEnrollment} />}
              {isPreAssessmentAvailable && (
                <PreviewCard
                  title={currentProgram.preAssessment.title}
                  excerpt={currentProgram.preAssessment.excerpt}
                  cta={
                    <Button
                      variant="grow"
                      href={buildLink(paths.snippets.preAssessment, { programSlug: programSlug })}
                      activeClassName="active"
                    >
                      <FormattedMessage id="cta.takeSurvey" defaultMessage="Take Survey" />
                    </Button>
                  }
                />
              )}
              {isPostAssessmentAvailable && (
                <PreviewCard
                  title={currentProgram.postAssessment.title}
                  excerpt={currentProgram.postAssessment.excerpt}
                  cta={
                    <Button
                      variant="grow"
                      href={buildLink(paths.snippets.postAssessment, { programSlug: programSlug })}
                      activeClassName="active"
                    >
                      <FormattedMessage id="cta.takeSurvey" defaultMessage="Take Survey" />
                    </Button>
                  }
                />
              )}
              {snippets.length === 0 && (
                <p>
                  <FormattedMessage
                    id="phrase.noSnippets"
                    defaultMessage="Your Snippets will show up here as soon as they are released. We will notify you when they become available!"
                    values={{ i: (text) => <i>{text}</i> }}
                  />
                </p>
              )}
              {orderedSnippets.map((snippet) => (
                <PreviewCard
                  key={snippet.id}
                  title={snippet.title}
                  excerpt={snippet.excerpt}
                  completed={isSnippetComplete(snippet.id)}
                  cta={
                    <Button
                      variant="grow"
                      href={
                        !isPreAssessmentAvailable &&
                        buildLink(paths.snippets.snippet, { programSlug: programSlug, snippetSlug: snippet.slug })
                      }
                      onClick={isPreAssessmentAvailable && (() => setPreAssessmentModalOpen(true))}
                      activeClassName="active"
                    >
                      <FormattedMessage
                        id="cta.viewSnippets"
                        defaultMessage="View Snippet"
                        values={{ i: (text) => <i>{text}</i> }}
                      />
                    </Button>
                  }
                />
              ))}
            </Col>
          </Row>
        </Container>
      </main>
      <AssessmentModal isOpen={preAssessmentModalOpen} setOpen={setPreAssessmentModalOpen} programSlug={programSlug} />
    </>
  )
}

export default Program
