import React from 'react'
import { FormattedMessage } from 'react-intl'
import DOMPurify from 'dompurify'
import cn from 'classnames'

import { NarrowContainer } from '..'
import styles from './rationale.module.scss'
import { Footnote } from '..'

const Rationale = ({ program, description, summary, references }) => (
  <NarrowContainer className={styles.container}>
    {summary && <div className={cn('mb-5', styles.summary)} dangerouslySetInnerHTML={{ __html: summary }} />}
    <h3 className={styles.title}>
      <FormattedMessage id="headings.rationale" defaultMessage="Here's Why" />
    </h3>
    <p className={styles.description} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
    <Footnote content={references} />
  </NarrowContainer>
)

export default Rationale
