import React from 'react'
import { Helmet } from 'react-helmet-async'
import { FormattedMessage, useIntl } from 'react-intl'

import { ForgotPasswordForm } from '../forms'
import { NarrowContainer } from '../components'

const ForgotPassword = () => {
  const { formatMessage } = useIntl()
  const title = {
    id: 'titles.pages.forgotPassword',
    defaultMessage: 'Forgot Password',
  }
  return (
    <NarrowContainer>
      <Helmet title={formatMessage(title)} />
      <h1>
        <FormattedMessage id="headings.forgotPassword" defaultMessage="Recover Password" />
      </h1>
      <ForgotPasswordForm />
    </NarrowContainer>
  )
}

export default ForgotPassword
