import React from 'react'
import Toggle from 'react-toggle'
import { FormattedMessage, useIntl } from 'react-intl'
import 'react-toggle/style.css'
import { Col } from 'react-bootstrap'
import { Row } from '../../components'
import { SelectField } from '../field'
import { SUPPORTED_LOCALES } from '../../i18n'

const Notifications = ({ onToggleEmails, onToggleNotifications, onToggleReminders, user = {}, formHooks }) => {
  const { sendReminders, subscribed, notified, profile = {} } = user
  const { locale } = profile
  const { register: registerField } = formHooks
  const { formatMessage } = useIntl()
  return (
    <>
      <Row>
        <Col md={6}>
          <p>
            <FormattedMessage id="inputs.labels.notifications" defaultMessage="Receive email notifications:" />
          </p>
        </Col>
        <Col md={6}>
          <Toggle defaultChecked={subscribed} onChange={onToggleEmails} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <FormattedMessage
              id="inputs.labels.mobileNotifications"
              defaultMessage="Receive mobile push notifications:"
            />
          </p>
        </Col>
        <Col md={6}>
          <Toggle defaultChecked={notified} onChange={onToggleNotifications} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p>
            <FormattedMessage id="inputs.labels.reminders" defaultMessage="Reminders:" />
          </p>
        </Col>
        <Col md={6}>
          <Toggle defaultChecked={sendReminders} onChange={onToggleReminders} />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SelectField
            id="locale"
            name="locale"
            testId="locale"
            fieldRef={registerField()}
            label={formatMessage({
              id: 'inputs.labels.notificationLanguage',
              defaultMessage: 'Notification language preference:',
            })}
            defaultValue={locale}
            options={SUPPORTED_LOCALES.map((item) => ({ value: item, label: formatMessage({ id: item }) }))}
          />
        </Col>
      </Row>
    </>
  )
}

export default Notifications
