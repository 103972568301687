import React from 'react'
import Modal from 'react-modal'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { paths, buildLink } from '../../router'

import { Button } from '../index'

import styles from './assessment-modal.module.scss'

const AssessmentModal = ({ isOpen = false, setOpen = () => {}, closable = true, programSlug }) => (
  <Modal
    isOpen={isOpen}
    onClose={() => setOpen(false)}
    onRequestClose={() => setOpen(false)}
    shouldCloseOnOverlayClick={closable}
    shouldCloseOnEsc={closable}
    overlayClassName={styles.modalOverlay}
    className={styles.modal}
    appElement={document.querySelector('#root')}
  >
    <div className={styles.modalHeader}>
      <h3 className={styles.modalHeading}>
        <FormattedMessage id="headings.completeTheSurvey" defaultMessage="Complete the Survey" />
      </h3>
      {closable && (
        <button className={styles.modalClose} onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      )}
    </div>
    <p>
      <FormattedMessage
        id="phrase.preSnippetSurveyModal"
        defaultMessage="Before you get started with your first Snippet, we need to know a little bit about you. This survey only takes 3 minutes."
        values={{ i: (text) => <i>{text}</i> }}
      />
    </p>
    <Button href={buildLink(paths.snippets.preAssessment, { programSlug })}>
      <FormattedMessage id="cta.getStarted" defaultMessage="Get Started" />
    </Button>
  </Modal>
)

export default AssessmentModal
