import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styles from './profile.module.scss'
import { Col } from 'react-bootstrap'
import Gravatar from 'react-gravatar'
import { Row, Button } from '../../components'
import { TextField } from '../field'

const Profile = ({ user, formHooks }) => {
  const { register: registerField, errors } = formHooks
  const { formatMessage } = useIntl()

  return (
    <div className={styles.profileBackground}>
      <Row>
        <Col md={6}>
          <TextField
            id="firstName"
            name="firstName"
            label={<FormattedMessage id="inputs.labels.firstName" defaultMessage="First Name" />}
            errors={errors.firstName}
            testId="firstName"
            fieldRef={registerField()}
            placeholder={formatMessage({
              id: 'inputs.placeholders.firstName',
              defaultMessage: 'Enter your first name',
            })}
            defaultValue={user.firstName}
            smallLabel
            boldLabel
          />
          <TextField
            id="lastName"
            name="lastName"
            label={<FormattedMessage id="inputs.labels.lastName" defaultMessage="Last Name" />}
            errors={errors.lastName}
            testId="lastName"
            fieldRef={registerField()}
            placeholder={formatMessage({ id: 'inputs.placeholders.lastName', defaultMessage: 'Enter your last name' })}
            defaultValue={user.lastName}
            smallLabel
            boldLabel
          />
          <TextField
            id="address"
            name="address"
            label={<FormattedMessage id="inputs.labels.address" defaultMessage="Address" />}
            errors={errors.address}
            testId="address"
            fieldRef={registerField()}
            placeholder={formatMessage({ id: 'inputs.placeholders.address', defaultMessage: 'Enter your address' })}
            defaultValue={user.profile?.address}
            smallLabel
            boldLabel
          />
          <TextField
            id="company"
            name="company"
            label={<FormattedMessage id="inputs.labels.company" defaultMessage="Company" />}
            errors={errors.company}
            testId="company"
            fieldRef={registerField()}
            placeholder={formatMessage({
              id: 'inputs.placeholders.company',
              defaultMessage: 'Enter your company name',
            })}
            defaultValue={user.profile?.company}
            smallLabel
            boldLabel
          />
          <TextField
            id="companyRole"
            name="companyRole"
            label={<FormattedMessage id="inputs.labels.companyRole" defaultMessage="Role" />}
            fieldRef={registerField()}
            testId="role"
            defaultValue={user.profile?.companyRole}
            smallLabel
            boldLabel
          />
        </Col>
        <Col md={6}>
          <div>
            <div className={styles.iconSettings}>
              <div className={styles.iconContainer}>
                <Gravatar className={styles.profileImage} email={user.email} />
              </div>
              <Button href="http://gravatar.com/emails/" target="_blank">
                <FormattedMessage id="cta.gravatar" defaultMessage="Change Photo on Gravatar" />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Profile
