import React from 'react'
import { useForm } from 'react-hook-form'
import { Spinner, Form } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@apollo/client'

import { Button } from '../../components'
import { mutations } from '../../api'
import { emailValidations } from '../validations'
import { TextField } from '../field'

import styles from '../form.module.scss'

const ForgotPasswordForm = () => {
  const { formatMessage } = useIntl()
  const {
    register: registerField,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm()
  const [requestPasswordReset] = useMutation(mutations.auth.requestPasswordReset)

  const onSubmit = async ({ email }) => {
    try {
      await requestPasswordReset({
        variables: {
          email,
        },
      })
    } catch (error) {
      console.error(error)
      setError('base', {
        message: formatMessage({
          id: 'errors.internalServerError',
          defaultMessage: 'There was a problem processing your request, please try again later.',
        }),
      })
    }
  }
  return (
    <>
      {isSubmitSuccessful ? (
        <p>
          <FormattedMessage
            id="phrase.passwordResetSent"
            defaultMessage="If account exists, an email will be sent with further instructions."
          />
        </p>
      ) : (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id={'forgot-password-email'}
            name={'email'}
            label={formatMessage({ id: 'inputs.labels.yourEmail', defaultMessage: 'Your Email' })}
            errors={errors.email}
            fieldRef={registerField(emailValidations)}
          />
          {errors.base && <div className={styles.errorAlertBox}>{errors.base.message}</div>}
          <Button
            type="submit"
            variant="primary"
            disabled={isSubmitting}
            className={styles.formButton}
            onClick={() => clearErrors('base')}
          >
            {isSubmitting ? (
              <>
                <Spinner animation="border" size="sm" variant="light" as="span" role="status" aria-hidden="true" />
                <span className="sr-only">
                  <FormattedMessage id="loading" defaultMessage="Loading" />
                  ...
                </span>
              </>
            ) : (
              <FormattedMessage id="cta.emailRecoveryLink" defaultMessage="Email me a recovery link" />
            )}
          </Button>
        </Form>
      )}
    </>
  )
}

export default ForgotPasswordForm
