import gql from 'graphql-tag'

export const answer = gql`
  mutation($responseId: Int!, $enrollmentId: ID!, $snippetId: ID!) {
    answerSnippet(responseId: $responseId, enrollmentId: $enrollmentId, snippetId: $snippetId) {
      id
      completedAt
      responses {
        id
        responseId
        snippetId
      }
    }
  }
`
