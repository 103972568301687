import { ApolloClient } from 'apollo-boost'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'

import 'cross-fetch/polyfill'

import * as token from '../auth/token'

const URL = process.env.REACT_APP_GRAPHQL_URL
const httpLink = createHttpLink({ uri: URL })

const authLink = setContext(async (_, { headers: existingHeaders }) => {
  const headers = {
    ...existingHeaders,
    Accept: 'application/json',
  }

  if (token.get()) {
    headers.Authorization = `Bearer ${token.get()}`
  }

  return { headers }
})

const localeLink = setContext(async (_, { headers }) => {
  const locale = localStorage.getItem('LS_locale')
  return { headers: { ...headers, 'Accept-Language': locale } }
})

const errorHandler = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // This method must return void or an Observable. If a Promise is returned
  // (like if we declare this to be async) it will expect it to resolve to
  // something Apollo-specific.

  if (networkError) {
    // Catch cases where the user's auth token has been revoked, and the refresh
    // has failed; our only option is to get them to log in again
    if (networkError.statusCode === 401) {
      // This client.__authMethods object comes from a hack in app.js,
      // documented there
      if (client.__authMethods) {
        client.__authMethods.refreshToken().then(({ success }) => {
          if (success) {
            forward(operation)
            return
          }
          client.__authMethods.logout({ skipApi: true })
        })
        return
      } else {
        console.warn(
          "Got a 401 on GraphQL query. Wanted to refresh token or log user out but don't (yet) have access to the auth methods. Ignoring."
        )
      }
      return
    }
    console.error(`[Network error]: ${networkError}`) // May need to handle these differently, just report them for now.
  }

  // From the Apollo docs:
  // GraphQL Errors: errors in the GraphQL results that can appear alongside successful data
  // Server Errors: server internal errors that prevent a successful response from being formed
  // Transaction Errors: errors inside transaction actions like update on mutations
  // UI Errors: errors that occur in your component code
  // Apollo Client Errors: internal errors within the core or corresponding libraries
  if (graphQLErrors) {
    for (const { message, locations, path } of graphQLErrors) {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    }
  }
})

const client = new ApolloClient({
  link: errorHandler.concat(authLink).concat(localeLink).concat(httpLink),
  cache: new InMemoryCache(),
})

export default client
