import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'

const Press = () => {
  const { formatMessage } = useIntl()
  const title = {
    id: 'titles.pages.press',
    defaultMessage: 'Press',
  }
  return (
    <>
      <Helmet title={formatMessage(title)} />
      <div>This is the press page</div>
    </>
  )
}

export default Press
