import React, { useState } from 'react'
import 'rc-slider/assets/index.css'
import Slider from 'rc-slider'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import formStyles from '../form.module.scss'
import styles from './profile.module.scss'
import { Col } from 'react-bootstrap'
import { Row } from '../../components'

const TextSize = ({ user, formHooks }) => {
  const [fontScale, setFontScale] = useState(user.profile?.fontScale || 1)
  const { register: registerField } = formHooks
  const marks = {
    8: '8',
    10: '10',
    12: '12',
    14: '14',
    16: '16',
    18: '18',
    20: '20',
  }

  const updateFontScale = (scale) => {
    setFontScale(scale)
    // setFieldValue(scale)
  }

  const fontSizeInPx = (scale) => {
    const px = {
      8: '12.8px',
      10: '16px',
      12: '19.2px',
      14: '22.4px',
      16: '25.6px',
      18: '28.8px',
      20: '32px',
    }

    return px[scale * 10] || '16px'
  }

  return (
    <Row className={styles.textSizeContainer}>
      <Col md={6}>
        <div className={styles.textSizeSliderContainer}>
          <span className={styles.smallSample} aria-hidden="true">
            A
          </span>
          <Slider
            min={8}
            max={20}
            marks={marks}
            onChange={(scale) => updateFontScale(scale / 10)}
            defaultValue={fontScale * 10}
            included={false}
            step={null}
            ariaLabelledByForHandle="sample_text_instructions"
          />
          <span className={styles.largeSample} aria-hidden="true">
            A
          </span>
        </div>
      </Col>
      <Col md={6}>
        <div>
          <label className={cn(formStyles.label, formStyles.smallLabel, formStyles.boldLabel)} aria-hidden="true">
            <FormattedMessage id="inputs.labels.sampleText" defaultMessage="Sample Text" />:
          </label>
          <p style={{ fontSize: fontSizeInPx(fontScale) }} id="sample_text_instructions">
            <FormattedMessage
              id="phrase.textSizeInstructions"
              defaultMessage="Drag the slider to adjust your default text size."
            />
          </p>
        </div>
      </Col>
      <input name="fontScale" type="hidden" value={fontScale} ref={registerField()} />
    </Row>
  )
}

export default TextSize
